import React, { useEffect, useState, useContext, Fragment } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import EDITAR from "../../../images/edit.png";
import StyledTable from "../tables/StyledTable";
import {
  GeneralsData,
  bodyResponseProvider,
  ContactData,
  Tax,
  Service,
  Contract,
} from "../../../interfaces/ProviderTypes";
import guardar from "../../../images/GUARDARDATOS.png";
import Generales from "../layout/Generales";
import ContactLayoutNew from "../layout/ContactLayoutNew";
import GetRowContact from "../layout/GetRowContact";
import GetContact from "../layout/GetContact";
import { v4 as uuidv4 } from "uuid";
import {
  objectCard,
  serviceObject,
  blackoutObject,
  validityDates,
  feeIndividual,
  quoteSearchParams,
  bodyI,
} from "../../../interfaces/GeneralTypes";
import { objetoPrueba } from "../../../context/initialContext";
import {
  fiscalContextInicial,
  registerGeneralData,
} from "../../../context/initialContext";
import GetFiscal from "../layout/GetFiscal";
import {
  faFilePdf,
  faFileImage,
  faClose,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Fee } from "../../../interfaces/ProviderTypes";
import ServiceGIndividual from "./ServiceGIndividual";
import ServiceHIndividual from "./ServiceHIndividual";
import GetServicio from "./GetServicio";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import DateInput from "../entrys/DateInput";
import { verificarFechaEnIntervalo, verificarFechaEnIntervaloDos } from "../../pages/RegisterNewProvider-Client/ExtraFunctions.ts/FunctionsTwo";
import { ValiditySearch, ValiditySearchDos } from "../../../interfaces/Cotizacion";
import { Validity } from "../../../interfaces/CotizacionI";

interface GetServiciosI {
  serviciosList: Service[] | undefined;
  getInfoProvider: () => void;
  setShowInfoContact: (show: boolean) => void;
  generalData: GeneralsData | undefined;
}
export default function GetServicios({
  serviciosList,
  getInfoProvider,
  setShowInfoContact,
  generalData
}: GetServiciosI) {
  const navigate = useNavigate();
  const [listParams, setListParams] = useState<quoteSearchParams[]>([]);
  const [idParamSelected, setIdParamSelected] = useState<number>(1);
  const [bodySearch, setBodySearch] = useState<bodyI>({
    dateSearch: "",
    textSearch: "",
    textSearchDos: "",
  });

  const { catalogsDataDos } = useCatalogsDos();
  useEffect(() => {
    console.log("Datos que vienen del método: ");
    console.log(catalogsDataDos);
    setListParams(catalogsDataDos.quote_search_parameter_list);
  }, [catalogsDataDos]);
  const filterProveedores = () =>{
    console.log("buscando..."); 
    if(idParamSelected == 1){
      // const fechaToFind =
      console.log("Lista de servicios");
      console.log(serviciosList);  
      const listFiltered = serviciosList?.filter((servicio, index)=>{
        const fechas:ValiditySearchDos[] = servicio.service_validity_list.map((fechasDato, index)=>{
          const fechasDatosV: ValiditySearchDos = {
            validity_identifier: fechasDato.service_identifier,
            fee_identifier: 2,
            validity_startdate: fechasDato.validity_startdate,
            validity_enddate: fechasDato.validity_enddate,
          }
          return fechasDatosV;
        })
        return verificarFechaEnIntervaloDos(bodySearch.dateSearch, fechas);
      })
      console.log("Nueva LISTA FILTRADA:::");
      console.log(listFiltered); 
    }
  }

  useEffect(()=>{
    filterProveedores(); 
  },[bodySearch.dateSearch])

  return (
    <GetServiciosStyles>
      <div className="title-first">
        <h3>Servicios/Tarifas</h3>
        <img
          src={EDITAR}
          onClick={() => {
            getInfoProvider();
            navigate("/dashboard/Registro/Servicios");
            setShowInfoContact(false);
          }}
        ></img>
      </div>
      <br />
      <br />
      {/* <div className="filtro- box">
        <div className="search-container">
          <div className="combo-box-container">
            <SelectListdefault
              style={{
                width: "80%",
                maxWidth: "80%",
              }}
              id="sl6"
              onChange={(e) => {
                // setIdParamSelected(parseInt(e.target.value));
              }}
            >
              <option value="" disabled selected>
                Criterio de busqueda
              </option>

              <Fragment>
                <option key={1} value={1} selected={1 == idParamSelected}>
                  Fecha
                </option>
                <option
                  key={2}
                  value={2}
                  // selected={2 == personalInfo.typeRoom}
                >
                  Tipo de Prestador
                </option>
                <option
                  key={3}
                  value={3}
                  //  selected={3 == personalInfo.typeRoom}
                >
                  Rango de precio
                </option>
                <option
                  key={3}
                  value={3}
                  //  selected={3 == personalInfo.typeRoom}
                >
                  Categoría
                </option>
              </Fragment>
            </SelectListdefault>
          </div>
          <div className="search-box-container">
            <DateInput
              text="Fecha"
              txtWidth={100}
              setGeneralData={setBodySearch}
              field="dateSearch"
              value={bodySearch.dateSearch}
              height={46.6}
            />
          </div>
          <div className="icon-container" 
          onClick={filterProveedores}
          >
                    <FontAwesomeIcon 
                    icon={faMagnifyingGlass} />
                  </div>
        </div>
      </div> */}
      <br />
      {serviciosList?.map((servicio, index) => {
        const feeList: Fee[] =
          servicio.service_type_identifier == 18
            ? servicio?.fees
            : [servicio?.fees[0]];

        return (
          <>
            <GetServicio
              getInfoProvider={getInfoProvider}
              serviciosList={serviciosList}
              setShowInfoContact={setShowInfoContact}
              servicio={servicio}
              feeList={feeList}
              generalData={generalData}
            />
          </>
        );
      })}
    </GetServiciosStyles>
  );
}

const GetServiciosStyles = styled.div`
  .icon-container {
    width: 15%;
    background-color: rgb(8, 166, 165);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  
    cursor: pointer;
    transition: ease all 0.7s;
    max-height: 46.6px;
  }
  .icon-container:hover {
    background-color: white;
    color: rgb(8, 166, 165);
  }
  .search-container {
    display: flex;
  }
  .input-container input {
    width: 100%;
    padding: 0.8rem;
    border: none;
    outline: none;
    border: rgb(8, 166, 165) solid 2px;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 46.6px !important;
  min-height: 46.6px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }

  ///////////////////////////////////
`;
