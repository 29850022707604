import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import editdatos from "../../../../images/edit.png";
import deletedatos from "../../../../images/deletelogo.png";
import FormReembolso from "./FormReembolso";
import DeleteConfirmation from "../../../components/common/DeleteConfirmation";
import { Item, MonthlyData, RefundObjectDelete } from "../InterfacesContabilidad";
import { fetchServer } from "../../../../services/fetchServer";
import { AppContext } from "../../../../context/appContext";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";

interface itemDay {
  currentDay: Item;
  getInitialData: () => Promise<void>;
  modeSwitch: number;
  setListResults: React.Dispatch<React.SetStateAction<MonthlyData[]>>,
  listResults: MonthlyData[];
  listForDelete: RefundObjectDelete[];
  setListForDelete: React.Dispatch<React.SetStateAction<RefundObjectDelete[]>>;
  deleteMode: boolean
}

export default function ReembolsoDiaContent({
  currentDay,
  getInitialData,
  modeSwitch,
  setListResults,
  listResults,
  listForDelete,
  setListForDelete,
  deleteMode
}: itemDay) {
  const { setShowLoader } = useContext(AppContext);

  const [isShownDelete, setIsShownDelete] = useState<boolean>(false);
  const [isShowingCurrentCard, setIsShowingCurrentCard] =
    useState<boolean>(false);
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteElement();
    setIsShownDelete(false);
    ////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };
  const deleteElement = async () => {
    console.log("Código para eliminar");
    console.log("payment identifier");
    console.log(currentDay.payment_identifier);
    const bodyDelete = {
      refund_identifier: currentDay.refund_identifier,
    };
    try {
      setShowLoader(true);
      const resJson = await fetchServer(
        `/accounting/refund/delete`,
        "post",
        bodyDelete
      );
      console.log(resJson);
      setShowLoader(false);
      getInitialData();
    } catch {
      alert("Fallo borrado");
      setShowLoader(false);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    console.log("Lista de días"); 
    console.log(listResults); 

    console.log("Mes actual");
    console.log("Id del refund");
    console.log(currentDay.refund_identifier);

    console.log("¿Positivo o negativo?");
    console.log(event.target.checked);
    if(event.target.checked){
        console.log("Agrega");
        let newArray:RefundObjectDelete[] = listForDelete;
        newArray.push({
          refund_identifier: currentDay.refund_identifier
        }); 

        console.log("Nuevo array:")
        console.log(newArray);
        setListForDelete(newArray); 
    }else{
      console.log("Borra");
      let newArray:RefundObjectDelete[] = listForDelete.filter((currentRefund, index)=>{
        return currentRefund.refund_identifier != currentDay.refund_identifier
      });
      
      console.log("Nuevo array:")
      console.log(newArray);
      setListForDelete(newArray);
      
    }
   


  };


  useEffect(()=>{
    console.log("Así queda tu lista para borrar-------------------------"); 
    console.log(listForDelete); 
  },[listForDelete])

  const [isCheckedOne, setIsCheckedTwo] = useState<boolean>(false); 
  return (
    <ReembolsoDiaContentStyle>
      {
        modeSwitch == 1 &&  <div
        className="content-day"
        style={{
          backgroundColor:
            currentDay.refund_status_identifier == 1 ? "#F9B7B7" : "#DBFCDB ",
        }}
      >
        <div>
          <h3>{currentDay.refund_status}</h3>
        </div>
        <div>
          <h3>{currentDay.creation_date}</h3>
        </div>
        <div>
          <h3>{currentDay.policy}</h3>
        </div>
        <div>
          <h3>{currentDay.provider_name}</h3>
        </div>
        <div>
          <h3>{currentDay.concept}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(currentDay.total_payment)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(currentDay.real_amount)}</h3>
        </div>
        <div>
          <h3>${formatearCantidad(currentDay.total_payment - currentDay.real_amount)}</h3>
        </div>
        <div>
          <h3>{currentDay.currency}</h3>
        </div>
        <div>
          <h3>{currentDay.modification_date + ' '+currentDay.user}</h3>
        </div>
        <div>
          <h3>{currentDay.reason}</h3>
        </div>
        <div className="father-box">
          <div className="child-box">
          <h3>{currentDay.comment}</h3>
          </div>
        </div>
        {/* <div className="comment-box">
          <h3>{currentDay.comment}</h3>
        </div> */}
        <div className="botones-box">
          <img
            src={editdatos}
            alt="edit-boton"
            width={25}
            className="boton-opcion"
            onClick={() => setIsShowingCurrentCard(true)}
          />
          {
            deleteMode &&     <input type="checkbox" 
            // checked ={isChecked}
            onChange={handleCheckboxChange}
          />
          }
      
          {/* <img
            src={deletedatos}
            onClick={() => setIsShownDelete(true)}
            alt="delete-boton"
            width={25}
            className="boton-opcion"
          /> */}
        </div>
      </div>
      }
    {
      modeSwitch == 2 &&   <div
      className="content-day content-day-dos"
      style={{
        backgroundColor:
          currentDay.refund_status_identifier == 1 ? "#F9B7B7" : "#DBFCDB ",
      }}
    >
      <div>
        <h3>{currentDay.refund_status}</h3>
      </div>
      <div>
        <h3>{currentDay.creation_date}</h3>
      </div>
      <div>
        <h3>{currentDay.agency}</h3>
      </div>
    
      <div>
        <h3>{currentDay.concept}</h3>
      </div>
      <div>
        <h3>{currentDay.total_payment}</h3>
      </div>
      <div>
        <h3>{currentDay.real_amount}</h3>
      </div>
      <div>
        <h3>{currentDay.positive_balance}</h3>
      </div>
      <div>
        <h3>{currentDay.currency}</h3>
      </div>
      <div>
        <h3>{currentDay.modification_date + ' '+currentDay.user}</h3>
      </div>
      <div>
        <h3>{currentDay.reason}</h3>
      </div>
      <div className="father-box">
          <div className="child-box">
          <h3>{currentDay.comment}</h3>
          </div>
        </div>
 
      <div className="botones-box">
        <img
          src={editdatos}
          alt="edit-boton"
          width={25}
          className="boton-opcion"
          onClick={() => setIsShowingCurrentCard(true)}
        />
        {
          deleteMode &&    <input type="checkbox"
          onChange={handleCheckboxChange}
  />
        }
     
        {/* <img
          src={deletedatos}
          onClick={() => setIsShownDelete(true)}
          alt="delete-boton"
          width={25}
          className="boton-opcion"
        /> */}
      </div>
    </div>
    }
      {isShowingCurrentCard && (
        <FormReembolso
          isShowingCurrentCard={isShowingCurrentCard}
          setIsShowingCurrentCard={setIsShowingCurrentCard}
          currentDay={currentDay}
          getInitialData={getInitialData}
          modeSwitch={modeSwitch}
        />
      )}

      {isShownDelete && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          itemName={`¿Deseas borrar la siguiente información de reembolso? ${currentDay.comment}`}
          setIsShownDelete={setIsShownDelete}
        />
      )}
    </ReembolsoDiaContentStyle>
  );
}
const ReembolsoDiaContentStyle = styled.div`
.comment-box{
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 40px;
  border: blue solid 1px;
}
.father-box {
    width: 100%;
    height: 40px;
    overflow-x: auto;
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  }

.comment-box h3{
  margin-bottom: 0.2rem;
}
  .boton-opcion {
    cursor: pointer;
  }
  .botones-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .content-day {
    width: 140%;
    border: black solid 1px;
    margin: 0 auto;
    margin-left: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 0.5rem;
    align-items: center;
  }

  .content-day div h3 {
    text-align: center;
    font-weight: 300;
    font-size: 0.85vw;
  }
  .content-day-dos{
    
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;
