import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import { OperationContext } from "../../../../context/operationContext";
import { AppContext } from "../../../../context/appContext";
import styled from "styled-components";
import { OperationSearchElementI } from "../InterfacesContabilidad";
import { useNavigate } from "react-router-dom";
interface ResultSearchGrupoI {
  grupo: OperationSearchElementI;
  indice: number;
}

export default function ResultSearchGrupos({
  grupo,
  indice,
}: ResultSearchGrupoI) {
  const { setIdOperation } = useContext(AppContext);
  const { setContabilidadMode, contabilidadLink } =
    useContext(OperationContext);
  const navigate = useNavigate();
  const checkGroup = () => {
    console.log("Checking group");
    setIdOperation(grupo.operation_identifier);
    setContabilidadMode(1);
    navigate("/dashboard/Contabilidad/Contabilidad-Prestadores");
  };
  const checkAuxiliares = () => {
    setIdOperation(grupo.operation_identifier);
    navigate("/dashboard/Contabilidad/Contabilidad-Auxiliares");
  };
  return (
    <ResultSearchStyle>
      {indice == 0 && (
        <div className="table-header">
          <div>
            <h3>Agencia</h3>
          </div>
          <div>
            <h3>Grupo</h3>
          </div>

          <div>
            <h3>Pax</h3>
          </div>
          <div>
            <h3>Fecha Llegada</h3>
          </div>
          <div>
            <h3>Fecha Salida</h3>
          </div>
          <div></div>
        </div>
      )}
      <div className="result-search-container">
        <div>
          <h3>{grupo.agency}</h3>
        </div>
        <div>
          <h3>{grupo.group}</h3>
        </div>

        <div>
          <h3>{grupo.pax}</h3>
        </div>
        <div>
          <h3>{grupo.arrival_date}</h3>
        </div>
        <div>
          <h3>{grupo.departure_date}</h3>
        </div>
        {contabilidadLink == 1 && (
          <div className="non-printable-section">
            <button onClick={checkGroup}>Visualizar</button>
          </div>
        )}
        {grupo.proforma_identifier !== 0 && (
          <div className="non-printable-section">
            {contabilidadLink == 2 && (
              <button onClick={checkAuxiliares}>Auxiliares</button>
            )}
          </div>
        )}
      </div>
    </ResultSearchStyle>
  );
}

const ResultSearchStyle = styled.div`
  .result-search-container {
    border: black solid 1px;
    /* width: 80%;
        margin: 0 auto; */
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr 0.7fr;
  }
  .result-search-container div h3 {
    text-align: center;
    font-size: 0.85vw;
    font-weight: 300;
  }
`;
