import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { OperationContext } from "../../../context/operationContext";
import TablePago from "./TablePago";
import {
  EntryDataI,
  PaymentData,
  PaymentRequestDataI,
  PendingPayment,
  ProviderContabilidadI,
  RequestModifyPayment,
  RootObject,
} from "./InterfacesContabilidad";
import { ProviderI } from "../../../interfaces/CotizacionI";
import { v4 as uuidv4 } from "uuid";
import FormPago from "./FormPago";
import { AppContext } from "../../../context/appContext";
import { useNavigate } from "react-router-dom";
import { fetchServer } from "../../../services/fetchServer";

export default function SavePago() {
  const {
    currentOperation,
    setCurrentOperation,
    currentEntrada,
    setCurrentEntrada,
    providerTypeOperacion,
    setProviderTypeOperacion,
    idProveedorSelected,
    setIdProveedorSelected,
    contabilidadMode,
    setIdCurrentPayment,
    idCurrentPayment,
  } = useContext(OperationContext);
  const { currentUser, setShowLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const [listPayments, setListPayments] = useState<PendingPayment[]>([]);
  const fechaActual: Date = new Date();

  // Obtiene los componentes de la fecha
  const año: number = fechaActual.getFullYear();
  const mes: number = fechaActual.getMonth() + 1; // ¡Recuerda que los meses comienzan desde 0!
  const dia: number = fechaActual.getDate();

  // Formatea la fecha en el formato deseado
  const fechaFormateada: string = `${año}-${mes < 10 ? "0" : ""}${mes}-${
    dia < 10 ? "0" : ""
  }${dia}`;
  const [inicialData, setInitialData] = useState<ProviderContabilidadI>({
    register_identifier: 0,
    provider_name: "",
    key: "",
    business_name: "",
    provider_type_identifier: 0,
    provider_type: "",
    fecha_pago: fechaFormateada,
    currency_identifier: 0,
    amount_money: 0,
    bank_identifier: 0,
    a_favor_de: "",
    concepto: "",
    numero_poliza: "",
    tipo_cambio: 0,
    paid_by: "",
    comments: "",
    account_identifier: 0,
    alias: "",
    agency: "",
  });
  const [providerInfo, setProviderInfo] = useState<ProviderContabilidadI>({
    register_identifier: 0,
    provider_name: "",
    key: "",
    business_name: "",
    provider_type_identifier: 0,
    provider_type: "",
    fecha_pago: fechaFormateada,
    currency_identifier: 0,
    amount_money: 0,
    bank_identifier: 0,
    a_favor_de: "",
    concepto: "",
    numero_poliza: "",
    tipo_cambio: 0,
    paid_by: "",
    comments: "",
    account_identifier: 0,
    alias: "",
    agency: "",
  });
  const validatePrice = (sumaPrecio: number, precioIngresado: number) => {
    console.log("precioIngresado > (sumaPrecio -1)");
    console.log(precioIngresado > sumaPrecio - 1);
    console.log("precioIngresado");
    console.log(precioIngresado);
    console.log("(sumaPrecio-1)");
    console.log(sumaPrecio - 1);
    console.log("precioIngresado < (sumaPrecio + 1)");
    console.log(precioIngresado < sumaPrecio + 1);
    return precioIngresado > sumaPrecio - 1 && precioIngresado < sumaPrecio + 1
      ? true
      : false;
  };
  const sendInfoPOST = async () => {
    const paymentGeneralInfoToSend: PaymentData = {
      payment_identifier: 0,
      payment_date: providerInfo.fecha_pago,
      currency_identifier: providerInfo.currency_identifier,
      total_payment: providerInfo.amount_money,
      bank_identifier: providerInfo.bank_identifier,
      bank_account_identifier: providerInfo.account_identifier,
      concept: providerInfo.concepto,
      policy: providerInfo.numero_poliza,
      exchange_rate: providerInfo.tipo_cambio,
      comment: providerInfo.comments,
      status_identifier: 0,
      business_name: providerInfo.business_name,
    };
    console.log("paymentGeneralInfoToSend");
    console.log(paymentGeneralInfoToSend);

    const listaEntradasAPagar: EntryDataI[] = listPayments
      .filter((currentPayment, index) => {
        return (
          currentPayment.isSelected &&
          currentPayment.payment_status !== "Pagado"
        );
      })
      .map((currentPayment, index) => {
        const entradaActual: EntryDataI = {
          entry_identifier: currentPayment.entry_identifier,
          currency_identifier: currentPayment.currency_identifier,
        };
        return entradaActual;
      });

    console.log("Info general por enviar:");
    console.log(paymentGeneralInfoToSend);
    console.log("OBJETO providerInfo");
    console.log(providerInfo);
    console.log("Lista de pagos seleccionada:");
    console.log(listaEntradasAPagar);

    const objetoEnviar: PaymentRequestDataI = {
      payment_data: paymentGeneralInfoToSend,
      entry_list: listaEntradasAPagar,
    };
    if (listaEntradasAPagar.length == 0) {
      alert("No hay más elementos por pagar");
      return;
    }

    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/accounting/payment/register`;
    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(objetoEnviar),
      });

      if (response.ok) {
        const data = await response.json();

        alert("El valor se ha enviado correctamente");

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        console.log(
          "-------------------------------------------DATA--------------------------------------"
        );
        console.log(data.data);
        navigate("/dashboard/Contabilidad/Contabilidad-Prestadores");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);

      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };
  const modifyPayment = async () => {
    const bodyRequest: RequestModifyPayment = {
      payment_identifier: idCurrentPayment,
      entry_identifier: 0,
      payment_date: providerInfo.fecha_pago,
      currency_identifier: providerInfo.currency_identifier,
      total_payment: providerInfo.amount_money,
      bank_identifier: providerInfo.bank_identifier,
      bank_account_identifier: providerInfo.account_identifier,
      concept: providerInfo.concepto,
      policy: providerInfo.numero_poliza,
      exchange_rate: providerInfo.tipo_cambio,
      comment: providerInfo.comments,
      business_name: providerInfo.a_favor_de,
      status_identifier: 1,
    };
    console.log("Datos enviados al modificar:");
    console.log(bodyRequest);

    try {
      setShowLoader(true);
      const resJson = await fetchServer(
        "/accounting/payment/update",
        "post",
        bodyRequest
      );
      setShowLoader(false);
      console.log("Respuesta JSON");
      console.log(resJson);
    } catch (error) {
      alert("Fallo el guardado");
    }
  };

  const guardarDatos = () => {
    if (contabilidadMode == 1) {
      sendInfoPOST();
    } else if (contabilidadMode == 2) {
      modifyPayment();
    }
  };
  const sendInfo = () => {
    console.log("List Payments");
    console.log(listPayments);
    if (
      providerInfo.currency_identifier == 1 &&
      providerInfo.tipo_cambio !== 0
    ) {
      alert("No puedes guardar tipo de cambio con un pago en dolares.");
      return;
    }

    let sumaPesos = listPayments
      .filter((payment, index) => {
        return (
          (payment.isSelected && payment.payment_status != "Pagado") ||
          contabilidadMode == 2
        );
      })
      .reduce((total, item) => total + item.advance_mxn, 0);

    let sumaDolares = listPayments
      .filter((payment, index) => {
        return (
          (payment.isSelected && payment.payment_status != "Pagado") ||
          contabilidadMode == 2
        );
      })
      .reduce((total, item) => total + item.advance_usd, 0);

    if (providerInfo.currency_identifier == 0) {
      alert("Por favor, elige una moneda");
      return;
    }
    if (providerInfo.amount_money == 0) {
      alert("Ingresa una cantidad de pago");
      return;
    }
    if (sumaPesos !== 0 && sumaDolares !== 0) {
      console.log(
        "Has seleccionado dos monedas diferentes, por favor selecciona sólo una"
      );
      alert(
        "Has seleccionado dos monedas diferentes, por favor selecciona sólo una"
      );
      return;
    } else {
      console.log("suma en pesos");
      console.log(sumaPesos);
      if (sumaPesos != 0 && providerInfo.currency_identifier == 2) {
        if (validatePrice(sumaPesos, providerInfo.amount_money)) {
          console.log("Los precios pasaron la validación.");
          alert("Los precios pasaron la validación.");
          guardarDatos();
          // sendInfoPOST();
        } else {
          console.log("Los precios NO coinciden");
          console.log("providerInfo.amount_money !== sumaPesos");
          alert("Los precios no coinciden, modificalos");
          return;
        }
        return;
      } else if (sumaDolares !== 0 && providerInfo.currency_identifier == 1) {
        if (validatePrice(sumaDolares, providerInfo.amount_money)) {
          console.log("Los precios pasaron la validación.");
          alert("Los precios pasaron la validación");
          guardarDatos();
          // sendInfoPOST();
          return;
        } else {
          console.log("Los precios NO coinciden");
          console.log("providerInfo.amount_money !== sumaPesos");
          alert("Los precios no coinciden, modificalos");
          return;
        }
      } else if (sumaDolares !== 0 && providerInfo.currency_identifier == 2) {
        if (providerInfo.tipo_cambio == 0) {
          alert("Ingresa un tipo de cambio");
          return;
        }

        let sumaDolaresConvertida = sumaDolares * providerInfo.tipo_cambio;

        if (validatePrice(sumaDolaresConvertida, providerInfo.amount_money)) {
          console.log("Los precios pasaron la validación.");
          alert("Los precios pasaron la validación");
          guardarDatos();
          // sendInfoPOST();
          return;
        } else {
          console.log("Los precios NO coinciden");
          console.log("providerInfo.amount_money !== sumaPesos");
          alert("Los precios no coinciden, modificalos");
          return;
        }
      } else if (sumaPesos !== 0 && providerInfo.currency_identifier == 1) {
        alert("No puedes pagar pesos con dolares");
        return;
      } else {
        alert(
          "Revisa que tu moneda coincida con la monedad de los servicios seleccionados.."
        );
        console.log("Suma pesos");
        console.log(sumaPesos);
        console.log("Suma dolares");
        console.log(sumaDolares);

        return;
      }
    }
  };
  const getInfoModificacion = async () => {
    ////accounting/paymentDetail/{paymentID}/{providerID}
    try {
      const data: RootObject = await fetchServer(
        `/accounting/paymentDetail/${idCurrentPayment}/${idProveedorSelected}`
      );
      console.log("Proveedor seleccionado:");
      console.log(data.data.pending_payments);
      console.log(data.data.provider);
      console.log(data.data.pending_payments);

      console.log(data.data.provider);
      let arregloPayments: PendingPayment[];
      arregloPayments = data.data.pending_payments;
      arregloPayments = arregloPayments.map((pago, index) => {
        let identifierDay = uuidv4();
        const payment: PendingPayment = {
          ...pago,
          id_local: identifierDay,
          isSelected: true,
        };
        return payment;
      });
      if (arregloPayments) {
        if (arregloPayments.length > 0) {
          setListPayments(arregloPayments);
        }
      }

      if (data.data.provider) {
        const proveedorInformacion: ProviderContabilidadI = {
          register_identifier: data.data.provider.register_identifier,
          provider_name: data.data.provider.provider_name,
          key: data.data.provider.key,
          business_name: data.data.provider.business_name,
          provider_type_identifier: data.data.provider.provider_type_identifier,
          provider_type: data.data.provider.provider_type,
          fecha_pago: data.data.provider.payment_date,
          currency_identifier: data.data.provider.currency_identifier,
          amount_money: data.data.provider.total,
          bank_identifier: data.data.provider.bank_identifier,
          a_favor_de: data.data.provider.business_name,
          concepto: data.data.provider.concept,
          numero_poliza: data.data.provider.policy,
          tipo_cambio: data.data.provider.exchange_rate,
          paid_by: data.data.provider.user_identifier,
          comments: data.data.provider.comment,
          account_identifier: data.data.provider.bank_account_identifier,
          alias: data.data.provider.alias,
          agency: data.data.provider.agency,
        };
        console.log("Datos de DB");
        console.log(data.data.provider);
        console.log("PROVEDOR A GUARDAR");
        console.log(proveedorInformacion);
        setInitialData(proveedorInformacion);
        setProviderInfo(proveedorInformacion);
      }
    } catch (error) {
      alert("Error en la petición");
    }
  };
  const getInfoPayment = async () => {
    console.log("Buscando...");

    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint +
      `/accounting/PendingPayments/${currentOperation.quote.quote_identifier}/${idProveedorSelected}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        // console.log(response);
        console.log("bien");
        const data: RootObject = await response.json();
        console.log(data.data.pending_payments);

        console.log(data.data.provider);
        let arregloPayments: PendingPayment[];
        arregloPayments = data.data.pending_payments;
        arregloPayments = arregloPayments.map((pago, index) => {
          let identifierDay = uuidv4();
          const payment: PendingPayment = {
            ...pago,
            id_local: identifierDay,
            isSelected: true,
          };
          return payment;
        });
        if (arregloPayments) {
          if (arregloPayments.length > 0) {
            setListPayments(arregloPayments);
          }
        }

        if (data.data.provider) {
          const proveedorInformacion: ProviderContabilidadI = {
            register_identifier: data.data.provider.register_identifier,
            provider_name: data.data.provider.provider_name,
            key: data.data.provider.key,
            business_name: data.data.provider.business_name,
            provider_type_identifier:
              data.data.provider.provider_type_identifier,
            provider_type: data.data.provider.provider_type,
            fecha_pago: fechaFormateada,
            currency_identifier: 0,
            amount_money: 0,
            bank_identifier: 0,
            a_favor_de: "",
            concepto: "",
            numero_poliza: "",
            tipo_cambio: 0,
            paid_by: "",
            comments: "",
            account_identifier: 0,
            alias: data.data.provider.alias,
            agency: data.data.provider.agency,
          };
          console.log("Datos de DB");
          console.log(data.data.provider);
          console.log("PROVEDOR A GUARDAR");
          console.log(proveedorInformacion);
          setInitialData(proveedorInformacion);
          setProviderInfo(proveedorInformacion);
        }

        if (data.data.pending_payments) {
          if (data.data.pending_payments.length > 0) {
          }
        }

        // console.log(data.data.results);
        // setListResultsGroups(data.data.results);
      } else {
      }
    } catch (error) {}
  };

  const printDocument = () => {
    window.print();
  };

  useEffect(() => {
    ////////////////La siguiente función trae la información del pago
    if (contabilidadMode == 2) {
      getInfoModificacion();
    } else {
      getInfoPayment();
    }
  }, []);
  useEffect(() => {
    console.log("Provider info cambio:");
    console.log(providerInfo);
  }, [providerInfo]);
  return (
    <SavePagoStyle>
      <div className="non-printable-section">
        <div className="print-box">
          <button onClick={printDocument}>Imprimir</button>
        </div>
      </div>

      <TablePago
        listPayments={listPayments}
        setListPayments={setListPayments}
        providerInfo={providerInfo}
        setProviderInfo={setProviderInfo}
      />
      <br />
      <FormPago
        providerInfo={providerInfo}
        setProviderInfo={setProviderInfo}
        listPayments={listPayments}
        setListPayments={setListPayments}
        inicialData={inicialData}
        saveInfo={sendInfo}
      />
      <div className="container-button non-printable-section">
        <button onClick={sendInfo}>Enviar</button>
      </div>
      <br />
      <br />
      <br />
    </SavePagoStyle>
  );
}

const SavePagoStyle = styled.div`
  .container-button {
    width: 85%;
    margin: 0 auto;
  }
  .container-button button {
    padding: 0.5rem 1.5rem;
    border-radius: 0.3rem;
    border: none;
    cursor: pointer;
  }
`;
