import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CustomInputt from "../../components/entrys/CustomInputt";
import {
  PendingPayment,
  ProviderContabilidadI,
} from "./InterfacesContabilidad";
import { useCatalogs } from "../../../context/CatalogContext";
import { coinInterface } from "../../../interfaces/GeneralTypes";
import CustomInputtt from "../../components/entrys/CustomInputtt";
import DateInput from "../../components/entrys/DateInput";
import { bankInterface } from "../../../context/InterfacesContext";
import { OperationContext } from "../../../context/operationContext";
import { isEqual } from "lodash";
import { useCatalogsOperacion } from "../../../context/OperacionCatalog";
import { BankAccountI } from "../Operaciones/Proforma/ProformaI";
import { useCatalogsContabilidad } from "../../../context/ContabilidadCatalogs";
import { BankAccountContabilidadI } from "../../../context/ContabilidadCatalogI";
import { AppContext } from "../../../context/appContext";
import CustomInputMoney from "../../components/entrys/CustomInputMoney";

interface FormPagoI {
  providerInfo: ProviderContabilidadI;
  setProviderInfo: React.Dispatch<React.SetStateAction<ProviderContabilidadI>>;
  listPayments: PendingPayment[];
  setListPayments: React.Dispatch<React.SetStateAction<PendingPayment[]>>;
  inicialData: ProviderContabilidadI;
  saveInfo: ()=>void
}
export default function FormPago({
  providerInfo,
  setProviderInfo,
  inicialData,
  saveInfo
}: FormPagoI) {
  const { catalogsData } = useCatalogs();
  const {currentUser, setCurrentUser} = useContext(AppContext);
  const {contabilidadMode} = useContext(OperationContext);
  const {catalogsDataContabilidad} = useCatalogsContabilidad();
  const { currentOperation } = useContext(OperationContext);
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const [providerLocalInfo, setProviderLocalInfo] =
    useState<ProviderContabilidadI>(providerInfo);
  const [listCuentas, setListCuentas] = useState< BankAccountContabilidadI[]>([])
  const [currencyList, setCurrencyList] = useState<coinInterface[]>([]);
  const [bankListTwo, setBankListTwo] = useState<bankInterface[]>([]);
    
/*
  const actualizarValorPadre = (nuevoValor: number) => {
    setCurrentUser(nuevoValor);
  }; 
*/

  useEffect(() => {
    console.log("Componente hijo cambio sus datos:");

    console.log(providerLocalInfo);
    setProviderInfo(providerLocalInfo);
    console.log("datos iniciales de provider info");
    console.log("providerInfo");
    console.log(providerInfo); 
  }, [providerLocalInfo]);
  useEffect(()=>{
    setProviderLocalInfo(providerInfo); 
  },[providerInfo])
  useEffect(() => {
    setBankListTwo(catalogsDataContabilidad?.banks_list);
    setCurrencyList(catalogsData?.currency_list);
    setListCuentas(catalogsDataContabilidad?.accounting_bank_accounts_list); 
    console.log("CATALOGS DATA CONTABILIDAD");
    console.log(catalogsDataContabilidad); 
  }, [catalogsDataContabilidad]);

  return (
    <FormPagoStyles>
      <div className="header-contabilidad">
        <h3 className="title-contabilidad">Registro para liquidar</h3>
        <br />
        <div className="container-titles headers-title">
        <div>
            {" "}
            <h3>Pagado por </h3>
          </div>
          <div>
            {" "}
            <h3>Clave grupo </h3>
          </div>
          <div>
            {" "}
            <h3>Agencia</h3>
          </div>
          <div>
            {" "}
            <h3>Tipo de prestador </h3>
          </div>
          <div>
            <h3>Prestador </h3>
          </div>
          <div>
            {" "}
            <h3>Clave </h3>
          </div>
        </div>
        <br />
        <div className="container-titles">
        <div>
            {" "}
            <h3>{contabilidadMode == 1 ? currentUser.user_name: providerInfo.paid_by}</h3>
          </div>
          <div>
            {" "}
            <h3>{providerInfo.alias} </h3>
          </div>
          <div>
            {" "}
            <h3>{providerInfo.agency}</h3>
          </div>
          <div>
            {" "}
            <h3>{inicialData.provider_type} </h3>
          </div>
          <div>
            <h3>{inicialData.provider_name}</h3>
          </div>
          <div>
            {" "}
            <h3>{inicialData.key} </h3>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div>
        <div>
          <h3></h3>
        </div>
      </div>
      <div>
        <div className="box-finantial-data">
          <DateInput
            text={true ? "Fecha de pago" : ""}
            txtWidth={100}
            setGeneralData={setProviderLocalInfo}
            field="fecha_pago"
            value={providerLocalInfo.fecha_pago}
          />
          <SelectListdefault
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {
              console.log("BANK IDENTIFIER SELECTED: ");
              console.log(e.target.value);
              //   setCardInfo({
              //     ...cardInfo,
              //     currency_element: parseInt(e.target.value),
              //   });
              setProviderLocalInfo({
                ...providerLocalInfo,
                currency_identifier: parseInt(e.target.value),
              });
            }}
            // disabled={!editionMode}
            value={providerLocalInfo.currency_identifier || ""}
          >
            <option
            className="default-opcion" value="" disabled selected>
              Moneda
            </option>

            {currencyList?.length > 0
              ? currencyList.map((item) => {
                  return (
                    <option
                      key={item.identifier}
                      value={item.identifier}
                      // selected={item.identifier == peo}
                    >
                      {item.description}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>

          <CustomInputMoney<ProviderContabilidadI>
            text="Cantidad pagada"
            txtWidth={100}
            setGeneralData={setProviderLocalInfo}
            field="amount_money"
            value={providerLocalInfo.amount_money}
            typeData="number"
            extraFunction={saveInfo}
          />
          <SelectListdefault
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {
              console.log("BANK IDENTIFIER SELECTED: ");
              console.log(e.target.value);
              //   setCardInfo({
              //     ...cardInfo,
              //     bank_identifier: parseInt(e.target.value),
              //   });
              setProviderLocalInfo({
                ...providerLocalInfo,
                bank_identifier: parseInt(e.target.value),
              });
            }}
            value={providerLocalInfo.bank_identifier || ""}
            // disabled={!editionMode}
          >
            <option value="" disabled selected>
              Banco
            </option>

            {bankListTwo?.length > 0
              ? bankListTwo.map((item) => {
                  return (
                    <option
                      key={item.identifier}
                      value={item.identifier}
                      //   selected={item.identifier == cardInfo.bank_identifier}
                    >
                      {item.description}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
          <SelectListdefault
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            id="sl6"
            onChange={(e) => {           
              setProviderLocalInfo({
                ...providerLocalInfo,
                account_identifier: parseInt(e.target.value),
              });
            }}
            value={providerLocalInfo.account_identifier || ""}
            // disabled={!editionMode}
          >
            <option value="" disabled selected>
              Cuentas de banco
            </option>

            {listCuentas?.length > 0
              ? listCuentas.map((item) => {
                  return (
                    <option
                      key={item.bank_account_identifier}
                      value={item.bank_account_identifier}
                      //   selected={item.identifier == cardInfo.bank_identifier}
                    >
                      {item.bank_account}
                    </option>
                  );
                })
              : null}
          </SelectListdefault>
        </div>

        <br />
        <div className="label-container">
          <CustomInputt<ProviderContabilidadI>
            text="A favor de"
            txtWidth={100}
            setGeneralData={setProviderLocalInfo}
            field="business_name"
            value={providerLocalInfo.business_name}
            extraFunction={saveInfo}
          />
          <br />
          <CustomInputt<ProviderContabilidadI>
            text="Concepto"
            txtWidth={100}
            setGeneralData={setProviderLocalInfo}
            field="concepto"
            value={providerLocalInfo.concepto}
            extraFunction={saveInfo}
          />
          <br />
        </div>
        <div className="data-aditional">
          <CustomInputt<ProviderContabilidadI>
            text="Número de poliza"
            txtWidth={100}
            setGeneralData={setProviderLocalInfo}
            field="numero_poliza"
            value={providerLocalInfo.numero_poliza}
            extraFunction={saveInfo}
          />

          <CustomInputtt<ProviderContabilidadI>
            text="Tipo de cambio"
            txtWidth={100}
            setGeneralData={setProviderLocalInfo}
            field="tipo_cambio"
            value={providerLocalInfo.tipo_cambio}
            typeData="number"
            extraFunction={saveInfo}
          />
       
        </div>

        <div className="box-field">
          <h3>Comentarios</h3>
          <textarea
            className="text-container"
            name={""}
            onKeyDown={(e)=>{
              
            }}
            id={""}
            onChange={(e) => {
              setProviderLocalInfo({
                ...providerLocalInfo,
                comments: e.target.value,
              });
            }}

            value={providerLocalInfo.comments}
          />
          <br />
          <br />
          <br />
        </div>
      </div>
    </FormPagoStyles>
  );
}

const FormPagoStyles = styled.div`

  .header-contabilidad {
    width: 85%;
    margin: 0 auto;
  }
  .header-contabilidad h3 {
    font-weight: 300;
    font-size: 0.85vw;
  }
  .title-contabilidad {
    color: rgb(8, 166, 165);
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .data-aditional {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 85%;
    grid-column-gap: 1rem;
    margin: 0 auto;
    justify-items: left;
  }
  .container-titles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .container-titles div h3 {
    text-align: center;
  }
  .label-container {
    width: 85%;
    margin: 0 auto;
  }
  .headers-title h3 {
    font-weight: 600 !important;
    color: rgb(8, 166, 165);
  }
  .box-finantial-data {
    width: 85%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    justify-items: left;
  }
  .box-fields {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  }

  .box-field {
    width: 85%;
    margin: 0 auto;
  }
  .box-field h3 {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
  }
  .text-container {
    resize: none;
    width: 100%;
    height: 200px;
    padding: 0.9rem;
    border: rgb(8, 166, 165) solid 2px;
    outline: none;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;

  border: 2px solid rgb(8, 166, 165);
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  max-height: 40.8px !important;
  .fake-icon {
    max-width: 10px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
