import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ListPrestadores from "./ListPrestadores";
import { AppContext } from "../../../context/appContext";
import {
  ApiResponseListProviders,
  ApiResponseServicesGuia,
  EntryService,
  EntryServiceI,
  GuideI,
  GuideNumberI,
  InitialContextOP,
  ProviderInterfaceOP,
} from "./InterfacesOperacion/OperacionesI";
import { List } from "@mui/material";
import { OperationContext } from "../../../context/operationContext";
import HeaderOpRead from "./HeaderOpRead";
import { useCatalogsOperacion } from "../../../context/OperacionCatalog";
import ElementGuide from "./Components/ElementGuide";
import addSymbol from "../../../images/AGREGAR.png";
import deleteSymbol from "../../../images/deletelogo.png";
import { v4 as uuidv4 } from "uuid";
import ElementAsignarGuia from "./Components/ElementAsignarGuia";
import ElementAsignarGuiaDos from "./Components/ElementAsignarGuiaDos";
import { Link, useNavigate } from "react-router-dom";
import guardardatos from "../../../images/GUARDARDATOS.png";

export default function AssignGuide() {
  const [seleccionarTodo, setSeleccionarTodo] = useState(false);
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const navigate = useNavigate();
  const [listGuides, setListGuide] = useState<GuideI[]>([]);
  const { currentOperation, currentEntrada, setShowLoader } =
    useContext(OperationContext);
  const { currentUser } = useContext(AppContext);
  const identificador = uuidv4();
  const [listGuidesNumbers, setListGuidesNumber] = useState<GuideNumberI[]>([
    {
      guide_identifier: 0,
      guide_id_key: identificador,
    },
  ]);
  const [listServiciosGuia, setListaServiciosGuia] = useState<EntryServiceI[]>(
    []
  );

  const getProviderList = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint +
      `/operations/getQuoteProviders/${currentOperation.quote.quote_identifier}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: ApiResponseListProviders = await response.json();

        // setQuoteInterfaceData(data.data.quote);
        // console.log("Datos de operación");
        // console.log(data.data);
        // console.log(
        //   "-------------------------------------------LISTA DE OPERACIONES:::::"
        // );
        // console.log("ID DE OPERACION:::");
        // console.log(idOperation);
        // console.log(data.data.providers);

        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");
      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
    }
  };
  const addGuia = () => {
    const identificadorInterno = uuidv4();
    setListGuidesNumber([
      ...listGuidesNumbers,
      {
        guide_identifier: 0,
        guide_id_key: identificadorInterno,
      },
    ]);
  };

  const updateGuia = (guiaSelected: GuideNumberI) => {
    const guiaArreglos = listGuidesNumbers.map((guideCurrent, index) => {
      return guideCurrent.guide_id_key !== guiaSelected.guide_id_key
        ? guideCurrent
        : {
            guide_identifier: guiaSelected.guide_identifier,
            guide_id_key: guiaSelected.guide_id_key,
          };
    });
    setListGuidesNumber(guiaArreglos);
  };
  const deleteGuia = (guiaSelected: GuideNumberI) => {
    if (listGuidesNumbers.length == 1) {
      alert("No puedes borrar más elemento de la lista");
      return;
    }
    const guiaArreglos = listGuidesNumbers.filter((guideCurrent, index) => {
      return guideCurrent.guide_id_key !== guiaSelected.guide_id_key;
    });
    setListGuidesNumber(guiaArreglos);
  };

  const aceptarServicios = () => {
    const nuevaTablaUno = listServiciosGuia.map((servicio, index) => {
      console.log(" !servicio.isSelected ");
      console.log(!servicio.isSelected);
      console.log("servicio.isShowedInSecondList");
      console.log(servicio.isShowedInSecondList);
      return servicio.isSelected
        ? {
            ...servicio,
            isShowedInSecondList: true,
            guides: listGuidesNumbers,
            isSelected: false,
          }
        : {
            ...servicio,
          };
    });
    console.log("NUEVA FORMA DE SERVICIOS");
    console.log(nuevaTablaUno);
    setListaServiciosGuia(nuevaTablaUno);

    setListGuidesNumber([
      {
        guide_identifier: 0,
        guide_id_key: identificador,
      },
    ]);
  };
  useEffect(() => {
    console.log("El arreglo de guias ha sido actualizado:");
    console.log(listGuidesNumbers);
  }, [listGuidesNumbers]);

  const getListGastos = async () => {
    //////Construcción de URL
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    ////AQUI CONSTRUYES TU ENPOINT; MODIFICALO

    const urlModified =
      backEndPoint +
      `/operations/getExpensesServices/${currentOperation.quote.quote_identifier}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");

        const data: ApiResponseServicesGuia = await response.json();
        let listServices = data.data.entry_services_list.map(
          (servicio, index) => {
            const identificador = uuidv4();
            return {
              ...servicio,
              isSelected: false,
              identifierService: identificador,
              isShowedInSecondList: false,
              quantity1: currentOperation.quote.pax,
              quantity2: 1,
            };
          }
        );

        console.log("Acceso a respuesta");
        console.log(data.data.entry_services_list);

        setListaServiciosGuia(listServices);

        // setShowLoader(false);
        ///Redirigir a otra pantalla
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      setShowLoader(false);
    }
  };
  useEffect(() => {
    getListGastos();
  }, []);

  useEffect(() => {
    console.log("CATALOGOS DE OPERACIONES::::");
    console.log(catalogsDataOperaciones);
    setListGuide(catalogsDataOperaciones.guides_list);
  }, [catalogsDataOperaciones]);

  const manejarSeleccionTodo = () => {
    const nuevoEstado = !seleccionarTodo;
    setSeleccionarTodo(nuevoEstado);
    listServiciosGuia.map((servicioActual, index) => {
      return (servicioActual.isSelected = nuevoEstado);
    });
  };

  const saveGastosAComprobar = async () => {
    setShowLoader(true);
    setShowLoader(false);
    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/entryUpdate`;
    let mxn_services: EntryService[] = listServiciosGuia
      .filter((servicio, index) => servicio.currency_identifier == 2)
      .map((servicio, indice) => {
        return {
          entry_service_identifier: servicio.entry_service_identifier,
          entry_identifier: servicio.entry_identifier,
          provider_identifier: servicio.provider_identifier,
          service_identifier: servicio.service_identifier,
          service_name: servicio.service_name,
          service_type_identifier: servicio.service_type_identifier,
          service_type: servicio.service_type,
          initial_validity: servicio.initial_validity,
          final_validity: servicio.final_validity,
          quantity1: servicio.quantity1,
          quantity2: servicio.quantity2,
          occupation: servicio.occupation,
          guide_identifier: null,
          iva: servicio.iva,
          taxes: servicio.taxes,
          unitary_fee: servicio.unitary_fee,
          total_fee: servicio.total_fee,
          currency_identifier: servicio.currency_identifier,
          currency: servicio.currency,
          guides: servicio.guides,
          provider_type_identifier: servicio.provider_identifier,
        };
      });
    let usd_services: EntryService[] = listServiciosGuia
      .filter((servicio, index) => servicio.currency_identifier == 1)
      .map((servicio, indice) => {
        return {
          entry_service_identifier: servicio.entry_service_identifier,
          entry_identifier: servicio.entry_identifier,
          provider_identifier: servicio.provider_identifier,
          service_identifier: servicio.service_identifier,
          service_name: servicio.service_name,
          service_type_identifier: servicio.service_type_identifier,
          service_type: servicio.service_type,
          initial_validity: servicio.initial_validity,
          final_validity: servicio.final_validity,
          quantity1: servicio.quantity1,
          quantity2: servicio.quantity2,
          occupation: servicio.occupation,
          guide_identifier: null,
          iva: servicio.iva,
          taxes: servicio.taxes,
          unitary_fee: servicio.unitary_fee,
          total_fee: servicio.total_fee,
          currency_identifier: servicio.currency_identifier,
          currency: servicio.currency,
          guides: servicio.guides,
          provider_type_identifier: servicio.provider_identifier,
        };
      });

    const bodyRequest: InitialContextOP = {
      entry_data: {
        entry_identifier: currentEntrada.entry_data.entry_identifier,
        operation_identifier: currentEntrada.entry_data.operation_identifier,
        provider_identifier: currentEntrada.entry_data.provider_identifier,
        status_identifier: 1,
        entry_number: currentEntrada.entry_data.entry_number,
        initial_date: currentEntrada.entry_data.initial_date,
        final_date: currentEntrada.entry_data.final_date,
        sgl: currentEntrada.entry_data.sgl,
        dbl: currentEntrada.entry_data.dbl,
        tpl: currentEntrada.entry_data.tpl,
        cpl: currentEntrada.entry_data.cpl,
        adults: currentEntrada.entry_data.adults,
        childs: currentEntrada.entry_data.childs,
        confirmation_number: currentEntrada.entry_data.confirmation_number,
        creator_user_identifier:
          currentEntrada.entry_data.creator_user_identifier,
        contact1_identifier: currentEntrada.entry_data.contact1_identifier,
        contact2_identifier: currentEntrada.entry_data.contact2_identifier,
        guide_identifier: currentEntrada.entry_data.guide_identifier,
        payday_limit: currentEntrada.entry_data.payday_limit,
        comment: currentEntrada.entry_data.comment,
        total_mxn: currentEntrada.entry_data.total_mxn,
        services_tips_mxn: currentEntrada.entry_data.services_tips_mxn,
        lodging_taxes_mxn: currentEntrada.entry_data.lodging_taxes_mxn,
        iva_mxn: currentEntrada.entry_data.iva_mxn,
        advance_mxn: currentEntrada.entry_data.advance_mxn,
        total_usd: currentEntrada.entry_data.total_usd,
        services_tips_usd: currentEntrada.entry_data.services_tips_usd,
        lodging_taxes_usd: currentEntrada.entry_data.lodging_taxes_usd,
        iva_usd: currentEntrada.entry_data.iva_usd,
        advance_usd: currentEntrada.entry_data.advance_usd,
        guide_courtesy: currentEntrada.entry_data.guide_courtesy,
        provider_name: currentEntrada.entry_data.provider_name,
        total_without_advance_usd:
          currentEntrada.entry_data.total_without_advance_usd,
        total_without_advance_mxn:
          currentEntrada.entry_data.total_without_advance_mxn,
        creator_user: currentEntrada.entry_data.creator_user,
      },
      entry_services_list: {
        mxn_services_list: mxn_services,
        usd_services_list: usd_services,
      },
    };

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();
        ////Check guardado
        // alert("El valor se ha enviado correctamente");

        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        setShowLoader(false);
        navigate("/dashboard/Operaciones/Definicion-Prestador");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);
      setShowLoader(false);
      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };

  return (
    <AssignGuideStyle>
      <h2 className="title-gastos">Asignar gastos a comprobar</h2>
      <div className="header-table-quotes">
        <div>
          <h3>Nombre</h3>
        </div>
        <div>
          <h3>Entrada</h3>
        </div>
        <div>
          <h3>Salida</h3>
        </div>
        <div>
          <h3>No. PAX</h3>
        </div>
        <div>
          <h3>Cantidad</h3>
        </div>

        <div>
          <h3>Tarifa total</h3>
        </div>
        <div>
          <h3>Moneda</h3>
        </div>
        <div className="combo">
          <h3>Seleccionar todo</h3>

          <input
            type="checkbox"
            checked={seleccionarTodo}
            onChange={manejarSeleccionTodo}
          />
        </div>
      </div>
      {listServiciosGuia
        .filter((servicioGuia, index) => !servicioGuia.isShowedInSecondList)
        .map((servicioGuia, index) => {
          return (
            <ElementAsignarGuia
              key={servicioGuia.identifierService}
              servicioGuia={servicioGuia}
              listServiciosGuia={listServiciosGuia}
              setListaServiciosGuia={setListaServiciosGuia}
            />
          );
        })}
      <br />
      <br />
      <div className="box-combos">
        <h3>Asignar a: </h3>
        <br />
        <br />
        {listGuidesNumbers.map((guiaSelected, index) => {
          return (
            <ElementGuide
              key={guiaSelected.guide_id_key}
              guiaSelected={guiaSelected}
              updateGuia={updateGuia}
              deleteGuia={deleteGuia}
            />
          );
        })}
        <img src={addSymbol} alt="add symbol" width="25" onClick={addGuia} />
      </div>
      <div className="button-box">
        <button className="next-button" onClick={aceptarServicios}>
          Aceptar
        </button>
      </div>
      <br />
      <br />

      {/* RENDERIZAR LAS TABLA DE GUIAS */}
      {listGuides
        .filter((guia, index) => {
          return listServiciosGuia.some((servicio, index) => {
            return servicio.guides.find(
              (guiaActual) =>
                guiaActual.guide_identifier == guia.provider_identifier
            );
          });
        })
        .map((guia, index) => {
          return (
            <>
              <br />
              <div className="title-table">
                <h3>{guia.provider_name}</h3>
              </div>
              <div className="header-table-quotes table-results">
                <div>
                  <h3>Nombre</h3>
                </div>
                <div>
                  <h3>Entrada</h3>
                </div>
                <div>
                  <h3>Salida</h3>
                </div>
                <div>
                  <h3>No. PAX</h3>
                </div>
                <div>
                  <h3>Cantidad</h3>
                </div>

                <div>
                  <h3>Tarifa total</h3>
                </div>
                <div>
                  <h3>Moneda</h3>
                </div>
                <div className="combo">
                  <h3>Seleccionar todo</h3>
                </div>
              </div>
              {listServiciosGuia
                .filter((servicio, index) => {
                  return servicio.guides.find(
                    (guiaActual) =>
                      guiaActual.guide_identifier == guia.provider_identifier
                  );
                })
                .map((servicioGuia, index) => {
                  return (
                    <>
                      <ElementAsignarGuiaDos
                        key={servicioGuia.identifierService}
                        servicioGuia={servicioGuia}
                        listServiciosGuia={listServiciosGuia}
                        setListaServiciosGuia={setListaServiciosGuia}
                      />
                    </>
                  );
                })}
            </>
          );
        })}

      <div className="buttons" style={{ backgroundColor: "transparent" }}>
        <img
          src={guardardatos}
          alt="leavePage"
          width="45px"
          onClick={saveGastosAComprobar}
          style={{ cursor: "pointer", marginBottom: "1rem" }}
        />
      </div>
    </AssignGuideStyle>
  );
}

const AssignGuideStyle = styled.div`
  .buttons {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 1rem;
    bottom: 0.2rem;
    background-color: #f2f2f2;
    z-index: 9;
  }
  .title-table {
    border: black solid 1px;
    padding: 0.5rem;
    background-color: rgb(8, 166, 165);
  }
  .title-table h3 {
    color: white;
    font-size: 0.85vw;
  }
  .button-box {
    display: flex;
    justify-content: right;
    padding-right: 2rem;
  }
  .next-button {
    background-color: transparent;
    border: none;
    padding: 1rem;
    background-color: rgb(8, 166, 165);
    border-radius: 0.8rem;
    padding: 0.5rem 2rem;
    color: white;
    cursor: pointer;
  }
  .box-combos {
    width: 40%;
  }
  .title-gastos {
    margin-top: 2rem;
    color: rgb(8, 166, 165);
  }
  .header-table-quotes {
    border: black solid 1px;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0.8rem;
    /* background-color: rgb(8, 166, 165); */
  }
  .header-table-quotes div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .combo input {
    margin-left: 0.5rem;
  }
  .header-table-quotes div h3 {
    font-weight: 300;
    font-size: 0.85vw;
    color: black;
    text-align: center;
  }
  .table-results {
    margin-top: 0;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: rgb(8, 166, 165) solid 2px;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  min-height: 38.4px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;
