import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { render } from "react-dom";
import { Component } from "react";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import {
  ApiResponseProvidersI,
  ComplaintContent,
  ComplaintRequest,
  ComplaintRequestI,
  ComplaintResponseGet,
  ComplaintTypeI,
  EntryDataResponse,
  InitialContextOPGastos,
  OperationInfoI,
  ProviderResponseI,
} from "./InterfacesOperacion/OperacionesI";
import { AppContext } from "../../../context/appContext";
import { useCatalogsOperacion } from "../../../context/OperacionCatalog";
import ElementQueja from "./Components/ElementQueja";
import { OperationContext } from "../../../context/operationContext";

Quill.register("modules/imageResize", ImageResize);

export default function ManageQuejas() {
  const [value, setValue] = useState("");
  const colors = [{ color: ["#e60000", "#9933ff", "#00ff00"] }];
  const { idOperation, setIdOperation } = useContext(AppContext);
  const { currentOperation } = useContext(OperationContext);
  const { catalogsDataOperaciones } = useCatalogsOperacion();
  const [listTypeComplaint, setListTypeComplaint] = useState<ComplaintTypeI[]>(
    []
  );
  const { currentUser, setShowLoader } = useContext(AppContext);
  const [generalInfoOp, setGeneralInfoOp] = useState<OperationInfoI>({
    operation_identifier: 0,
    alias: "",
    agency: "",
    arrival_date: "",
    departure_date: "",
  });
  // const [tipoQuejaList, setTipoQuejaList] = useState();
  useEffect(() => {
    console.log("CATALOGOS DE OPERACIONES::::");
    console.log(catalogsDataOperaciones);
    setListTypeComplaint(catalogsDataOperaciones.complaint_type_list);
  }, [catalogsDataOperaciones]);
  useEffect(() => {
    console.log("Se muestra la lista de tipo de quejas");
    console.log(listTypeComplaint);
  }, [listTypeComplaint]);
  var toolbarOne = [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", { color: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
    ["resize"],
  ];
  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const modules = {
    toolbar: toolbarOne,
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  useEffect(() => {}, [value]);

  useEffect(() => {
    getListProvidersQuejas();
    getListQuejas();
  }, []);
  const [listProveedores, setListProveedores] = useState<ProviderResponseI[]>([]);
  const [complaintBody, setComplaintBody] = useState<ComplaintRequest>({
    operation_identifier: idOperation,
    complaint_type_identifier: 1,
    provider_identifier: 0,
    agency: "",
    complaint_body: "",
  });
  // const [operationInfo, set]
  const getListProvidersQuejas = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    ////AQUI CONSTRUYES TU ENPOINT; MODIFICALO
    const urlModified =
      backEndPoint + `/operations/operationProviders/${idOperation}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: ApiResponseProvidersI = await response.json();
        console.log("Consultar lista de proveedores::::::");
        console.log(data.data.operation);
        console.log(data.data.providers);
        setListProveedores(data.data.providers);
        setGeneralInfoOp(data.data.operation);
        ///Redirigir a otra pantalla
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
    }
  };
  const [listQuejasConsulta, setListQuejasConsultas] = useState<
    ComplaintContent[]
  >([]);
  const getListQuejas = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    ////AQUI CONSTRUYES TU ENPOINT; MODIFICALO
    const urlModified =
      backEndPoint + `/operations/getOperationComplaints/${idOperation}`;
    setShowLoader(true);
    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },

        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: ComplaintResponseGet = await response.json();
        console.log("Consultar lista de quejas::::::");
        console.log(data.data);
        setListQuejasConsultas(data.data);
        ///Redirigir a otra pantalla
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
        setShowLoader(false);
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      setShowLoader(false);
    }
  };

  const saveQueja = async () => {
    console.log("queja a enviar ");
    if (complaintBody.complaint_type_identifier == 1) {
      if (complaintBody.provider_identifier == 0) {
        alert("Por favor, ingresa la información necesaria: <<id provider>>");
        return;
      }
   
    } else if (complaintBody.complaint_type_identifier == 2) {
      if (!complaintBody.agency.trim()) {
        alert("Por favor, ingresa la información necesaria <<agencia>>");
        return;
      }
   
    }
    

    const bodyRequest: ComplaintRequestI = {
      operation_identifier: idOperation,
      complaint_type_identifier: complaintBody.complaint_type_identifier,
      provider_identifier: complaintBody.provider_identifier,
      agency: complaintBody.agency,
      complaint_body: value,
    };
   
    setShowLoader(true);
    const urlUpdate = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/registerComplaint`;

    if (complaintBody.complaint_type_identifier == 1) {
    }

    try {
      const response = await fetch(urlUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_identifier: currentUser.user_identifier.toString(),
        },
        body: JSON.stringify(bodyRequest),
      });

      if (response.ok) {
        const data = await response.json();

    
        console.log("Se guardó la queja correctamente:::");
        console.log(data);
        ///////////////////////////////////OBTENER DATOS DE ENTRADA ACTUAL DE OPERACION
        getListQuejas();
        setValue("");
        setComplaintBody({
          operation_identifier: idOperation,
          complaint_type_identifier: 1,
          provider_identifier: 0,
          agency: "",
          complaint_body: "",
        });
        // navigate("/dashboard/Operaciones/Definicion-Prestador");
        ///////////////////////////////////////////////////////////
      } else {
        console.error("Error in response:", response);
        alert("Error al guardar");
        setShowLoader(false);
        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al guardar");
      console.error("Error in request:", error);
      setShowLoader(false);
      alert("Error en el servicio");
      // setShowLoader(false);
    }
  };
  const fechaHoy: string = obtenerFechaDeHoy();
  return (
    <ManageQuejasStyles>
      <h2 className="quejas-general-title">Quejas</h2>
      <h3>
        Operación:{" "}
        {generalInfoOp.alias.trim()
          ? generalInfoOp.alias
          : generalInfoOp.agency}
      </h3>
      <br />
      <h3 className="instruccion-queja"> Generar nueva queja</h3>
      <SelectListdefault
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
        id="sl6"
        onChange={(e) => {
          setComplaintBody({
            ...complaintBody,
            complaint_type_identifier: parseInt(e.target.value),
          });
        }}
        value={complaintBody.complaint_type_identifier || ""}
      >
        <option value="" disabled selected>
          Tipo de queja
        </option>

        {listTypeComplaint.length > 0
          ? listTypeComplaint.map((item) => {
              return (
                <option
                  key={item.complaint_type_identifier}
                  value={item.complaint_type_identifier}
                  // selected={
                  //   item.provider_type_identifier ==
                  //   currentGuia.guide_identifier
                  // }
                >
                  {item.complaint_type}
                </option>
              );
            })
          : null}
      </SelectListdefault>
      {complaintBody.complaint_type_identifier == 1 ? (
        <div className="box-info-queja">
          <div>
            <SelectListdefault
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
              id="sl6"
              onChange={(e) => {
                setComplaintBody({
                  ...complaintBody,
                  provider_identifier: parseInt(e.target.value),
                });
                // const quejaActual = listQuejasConsulta.find((queja, index)=> queja.provider_identifier == parseInt(e.target.value))?.complaint_body;
                // if(quejaActual){
                //     setValue(quejaActual);
                // }
              }}
              value={complaintBody.provider_identifier || ""}
            >
              <option value="" disabled selected>
                Proveedores
              </option>

              {listProveedores.length > 0
                ? listProveedores.map((item) => {
                    return (
                      <option
                        key={item.register_identifier}
                        value={item.register_identifier}
                        // selected={
                        //   item.provider_type_identifier ==
                        //   currentGuia.guide_identifier
                        // }
                      >
                        {item.provider_name}
                      </option>
                    );
                  })
                : null}
            </SelectListdefault>
          </div>

          <div className="info-individual">
            <h3 className="title-description">Fecha de llegada:</h3>
            <h3>
              {" "}
              {listProveedores.find(
                (proveedor, indice) =>
                  proveedor.register_identifier ==
                  complaintBody.provider_identifier
              )?.arrival_date ?? ""}
            </h3>
          </div>

          <div className="info-individual">
            <h3 className="title-description">Fecha de salida:</h3>
            <h3>
              {" "}
              {listProveedores.find(
                (proveedor, indice) =>
                  proveedor.register_identifier ==
                  complaintBody.provider_identifier
              )?.departure_date ?? ""}
            </h3>
          </div>

          <div className="info-individual">
            <h3 className="title-description">Fecha:</h3>
            <h3> {fechaHoy}</h3>
          </div>
          <div className="info-individual">
            <h3 className="title-description">Grupo:</h3>
            <h3> Información</h3>
          </div>
        </div>
      ) : complaintBody.complaint_type_identifier == 2 ? (
        <div className="box-info-agencia">
          <div>
            <h3>{fechaHoy}</h3>
          </div>
          <div>
            <input
              className="input-agencia"
              type="text"
              onChange={(e) => {
                setComplaintBody({
                  ...complaintBody,
                  agency: e.target.value,
                });
              }}
              value={complaintBody.agency}
              placeholder="Agencia"
            />
          </div>
        </div>
      ) : null}

      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
      />
      <div className="boton-contenedor">
        <button className="quejas-boton" onClick={saveQueja}>
          Guardar
        </button>
      </div>

      <h2 className="historial">Historial de quejas</h2>
      {listQuejasConsulta.length > 0 && (
        <>
          {listQuejasConsulta.map((complaintCurrent, index) => {
            return (
              <>
                <ElementQueja
                  key={complaintCurrent.complaint_identifier}
                  complaintCurrent={complaintCurrent}
                />
              </>
            );
          })}
        </>
      )}
    </ManageQuejasStyles>
  );
}

const ManageQuejasStyles = styled.div`
  width: 70%;
  margin: 0 auto;
  .ql-editor {
    min-height: 150px;
  }
  .info-individual {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
  .title-description {
    color: rgb(8, 166, 165);
  }
  .boton-contenedor {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-end;
  }
  .quejas-boton {
    border: none;
    outline: none;
    background-color: rgb(8, 166, 165);
    color: white;
    padding: 0.4rem 2rem;
    border-radius: 0.8rem;
    margin-top: 0.6rem;
    cursor: pointer;
  }
  .historial {
    font-size: 1.2vw;
    text-align: center;
    margin-bottom: 1rem;
  }
  .quejas-general-title {
    font-size: 1.5vw;
    color: rgb(8, 166, 165);
    text-align: center;
    margin-bottom: 2rem;
  }
  .instruccion-queja {
    color: rgb(8, 166, 165);
    margin-bottom: 1rem;
  }
  .input-agencia {
    padding: 0.3rem;
    outline: none;
    border: none;
    border: rgb(8, 166, 165) solid 1px;
  }
  .box-info-queja {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .box-info-queja div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
  .box-info-agencia {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .box-info-agencia div h3 {
    text-align: center;
    font-size: 0.85vw;
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: rgb(8, 166, 165) solid 2px;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  margin-bottom: 0.5rem;
  min-height: 38.4px !important;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;

function obtenerFechaDeHoy(): string {
  const fechaHoy: Date = new Date();
  const dia: number = fechaHoy.getDate();
  const mes: number = fechaHoy.getMonth() + 1; // Los meses comienzan desde 0, por lo tanto, se suma 1.
  const año: number = fechaHoy.getFullYear();

  // Formatear el día y el mes para que tengan siempre dos dígitos
  const diaFormateado: string = dia < 10 ? `0${dia}` : dia.toString();
  const mesFormateado: string = mes < 10 ? `0${mes}` : mes.toString();

  // La función devuelve la fecha en formato "YYYY-MM-DD"
  return `${año}-${mesFormateado}-${diaFormateado}`;
}
