import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import GerenteTable from "../../Operaciones/GerenteTable";

interface optionI {
  optionSelected: number;
  setOptionSelected: React.Dispatch<React.SetStateAction<number>>;
  changeStatus: (requestNumber: number) => void;
}

export default function OptionSwitchAux({
  optionSelected,
  setOptionSelected,
  changeStatus,
}: optionI) {
  useEffect(() => {
    console.log("Valor de option selected:");
    console.log(optionSelected);
  }, [optionSelected]);
  return (
    <OptionSwitchStyles>
      <div className="non-printable-section">
        <div className="container-options">
          <div
            className={`${optionSelected == 1 ? "abrir-grupo" : ""}`}
            onClick={() => {
              changeStatus(1);
            }}
          >
            <h3>Abrir grupo</h3>
          </div>
          <div
            className={`${optionSelected == 2 ? "cerrar-grupo" : ""}`}
            onClick={() => {
              changeStatus(3);
            }}
          >
            <h3>Cerrar Grupo</h3>
          </div>
        </div>
      </div>
    </OptionSwitchStyles>
  );
}

const OptionSwitchStyles = styled.div`
  .container-options {
    border-radius: 0.5rem;
    width: 250px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 6px 2px;
  }
  .container-options div {
    border-radius: 0.3rem;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    cursor: pointer;
  }
  .active-boton {
    border: #f6921e solid 1px;
    background-color: #f6921e;
  }
  .abrir-grupo {
    background-color: #39b54a;
    color: white;
  }
  .cerrar-grupo {
    color: white;
    background-color: #ed1c24;
  }

  h3 {
    font-weight: 600;
    font-size: 0.9vw;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1.5vw;
    }
  }
`;
