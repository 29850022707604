import React, { Fragment, useState, useEffect, useContext } from "react";
import StyledEntry from "../../components/entrys/StyledEntry";
import styled from "styled-components";
import { AppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faBan,
  faRotateRight,
  faXmark,
  faBroom,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import ContactLayout from "../../components/layout/ContactLayout";
import { v4 as uuidv4 } from "uuid";
import ContactLayoutNew from "../../components/layout/ContactLayoutNew";
import agregar from "../../../images/AGREGAR.png";
import CotizacionInfo from "./CotizacionInfo";
import GeneralesCotizacion from "./GeneralesCotizacion";
import edit from "../../../images/edit.png";
import {
  servicioDayElementI,
  servicioIndividual,
  serviciosDaysElementsI,
} from "../../../context/InterfacesContext";
import deletelogo from "../../../images/deletelogo.png";
import { useCatalogs } from "../../../context/CatalogContext";
import {
  serviceInterface,
  categoriaI,
  lenderI,
  City,
  maealI,
  ProviderType,
} from "../../../interfaces/GeneralTypes";
import {
  NameProvider,
  searchDataDosI,
  searchDataI,
} from "../../../interfaces/Cotizacion";
import { ProviderSearchList } from "../../../interfaces/Cotizacion";
import { generalElementI } from "../../../context/InterfacesContext";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import { obtenerFechaDesdeString } from "../RegisterNewProvider-Client/FunctionsTarifa";
import { useCatalogsDos } from "../../../context/CatalogContextDos";
import {
  OperationNewProviders,
  ResponseServicesOp,
  ServiceOP,
  ServiceOPNew,
} from "../Operaciones/InterfacesOperacion/OperacionesI";
import SearchServiceElement from "./SearchServiceElement";
import { OperationContext } from "../../../context/operationContext";
import { calcularNumeroDeNoches } from "../Operaciones/functionsOP";
import { useCatalogsOperacion } from "../../../context/OperacionCatalog";

export default function SearchServicesDOS() {
  const {
    setDataCotizacionContext,
    dataCotizacionContext,
    cotizationDate,
    setShowLoader,
    serviciosType,
    route,
    currentCurrency,
    setShowSearchProviders,
  } = useContext(AppContext);

  const { currentOperation, contadorOperacion, setContadorOperacion } =
    useContext(OperationContext);
  const [providerType, setProviderType] = useState<ProviderType[]>([]);
  const [serviceList, setServiceList] = useState<serviceInterface[]>([]);
  const [categoryServices, setCategoryServices] = useState<categoriaI[]>([]);
  const [prestador, setPrestador] = useState<lenderI[]>([]);
  const [cityList, setCityList] = useState<City[]>([]);
  const [foodPlan, setFoodPlan] = useState<maealI[]>([]);
  const [paramsSearch, setParamsSearch] = useState<searchDataDosI>({
    service_date: "",
    service_type_identifier: null,
    category_identifier: null,
    lender_type_identifier: null,
    register_identifier: null,
    search_parameter: "",
    city_identifier: null,
    mealplan_identifier: null,
    room_type: dataCotizacionContext.personalInfo.typeRoom ?? 1,
    provider_type_identifier: null,
    provider_identifier: null,
    end_date: "",
  });
  const { catalogsDataDos } = useCatalogsDos();
  
  const {catalogsDataOperaciones} = useCatalogsOperacion();

  const [listResults, setListResults] = useState<ProviderSearchList[] | null>(
    null
  );
  const getCitiesFromCountries = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/catalog/Country_Cities/${117}`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("Trayendo datos del país");
        // console.log(data.data);
        setCityList(data.data.city_list);
      })
      .catch((error) => console.error(error));
  };

  const { catalogsData } = useCatalogs();
  const cleanInformation = () => {
    setParamsSearch({
      ...paramsSearch,
      search_parameter: "",
      service_type_identifier: null,
      category_identifier: null,
      lender_type_identifier: null,
      register_identifier: null,
      city_identifier: null,
      mealplan_identifier: null,
      provider_type_identifier: null,
      provider_identifier: null,
      end_date: "",
      service_date: "",
    });
    setListResults(null);
    setListResultsServices([]);
    console.log("LIMPIANDO INFORMACION: ");
    limpiarBotonesGris();
    setListResultsProviders([]);
  };

  useEffect(() => {
    console.log(
      "----------------------------------------Valores del catalogsData----------------------------------------------"
    );
    getCitiesFromCountries();
    console.log(catalogsData);

    setCategoryServices(catalogsData?.category_list);
    setCategoryServices(catalogsData?.category_list);
    setPrestador(catalogsData?.lender_type_list);
    setFoodPlan(catalogsData?.meal_plan_list);
    setProviderType(catalogsData?.provider_type_list);
  }, [catalogsData]);
  useEffect(() => {
    console.log("Lista de operaciones:::");
    console.log(catalogsDataOperaciones?.service_type_list); 
    console.log("------")
    setServiceList(catalogsDataOperaciones?.service_type_list);
  }, [catalogsDataOperaciones]);
  useEffect(() => {
    console.log("PROVIDER TIPE LIST:");
    console.log(providerType);
  }, [providerType]);
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      console.log("Apretaste escape");
      setShowSearchProviders(false);
    }
  };
  useEffect(() => {
    console.log("Actualizacndo paramsSearch");
    console.log(paramsSearch);
    if (
      paramsSearch.service_type_identifier != 10 &&
      paramsSearch.mealplan_identifier != null
    ) {
      console.log("Haciendo null paramsSearch");
      setParamsSearch({
        ...paramsSearch,
        mealplan_identifier: null,
      });
    }
  }, [paramsSearch]);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const [listResultsProviders, setListResultsProviders] = useState<
    NameProvider[]
  >([]);
  const [listResultsServices, setListResultsServices] = useState<ServiceOP[]>(
    []
  );

  const searchProveedores = async () => {
    console.log("PARAMETROS DE BUSQUEDA:");
    console.log(paramsSearch.search_parameter);

    if (true) {
      const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/searchProvider`;
      const user = "userEmail";
      const password = "userPassword";
      setShowLoader(true);
      console.log("cuerpo de busqueda");
      console.log(paramsSearch);
      try {
        const response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paramsSearch),
        });

        if (response.ok) {
          const data: ResponseServicesOp = await response.json();
          console.log("Datos de busqueda:", data.data.services_list);
          let listServices: ServiceOP[] = data.data.services_list;

          listServices = listServices.map((currentServicio, indice) => {
            const identificador = uuidv4();
            return {
              ...currentServicio,
              cantidad_uno: 0,
              cantidad_dos: numeroDeNoches,
              identificador_local: identificador,
            };
          });
          console.log(
            "Así quedó tu lista de servicios con identificador y cantidades"
          );

          if (listServices.length == 0) {
            alert("No se encontraron coincidencias");
            setShowLoader(false);
            return;
          } else {
            setListResultsServices(listServices);
            setListResultsProviders(data.data.provider_list_search);
            console.log(listServices);
          }
          setShowLoader(false);
          // if (data.data.provider_list) {
          //   if (data.data.provider_list.length == 0) {
          //     alert("No se encontraron coincidencias");
          //   }

          //   return;
          //   setListResults(data.data.provider_list);
          //   setListResultsProviders(data.data.provider_list_search);
          // } else {
          //   alert("No se encontraron coincidencias");
          // }
        } else {
          console.error("Error in response:", response);
          alert("Error al realizar la busqueda");

          setShowLoader(false);
          // setTitleAlert('Error al iniciar sesión');
          // setContentAlert('Por favor, digite un email y contraseña valido.');
          // setShowAlert(true);
        }
      } catch (error) {
        alert("Error al realizar la busqueda");
        console.error("Error in request:", error);
        alert("Error en el servicio");

        setShowLoader(false);
        // setShowLoader(false);
      }
    }
  };
  useEffect(() => {
    console.log("Nueva LISTA DE PROVEEDORES:::");
    console.log(listResultsProviders);
  }, [listResultsProviders]);

  // Crear un estado local para seguir el primer renderizado
  const [primerRender, setPrimerRender] = useState(true);
  useEffect(() => {
    if (primerRender) {
      // Actualizar el estado para indicar que no es la primera renderización
      setPrimerRender(false);
    } else {
      // Aquí puedes colocar la lógica que deseas ejecutar cuando cambie una dependencia
      console.log("La dependencia ha cambiado");
      if (
        paramsSearch.provider_type_identifier !== null ||
        paramsSearch.service_type_identifier !== null ||
        paramsSearch.category_identifier !== null ||
        paramsSearch.city_identifier !== null ||
        paramsSearch.mealplan_identifier ||
        null
      ) {
        searchProveedores();
      }
    }
  }, [
    paramsSearch.service_type_identifier,
    paramsSearch.category_identifier,
    paramsSearch.city_identifier,
    paramsSearch.mealplan_identifier,
    paramsSearch.provider_type_identifier,
    paramsSearch.provider_identifier,
  ]);

  const [botonesGris, setBotonesGris] = useState(new Set());

  const toggleBotonGris = (index: number, room_type: string) => {
    const key = `${index}-${room_type}`;

    // const key = `${index}-${room_type}`;
    if (!botonesGris.has(key)) {
      botonesGris.add(key);
      setBotonesGris(new Set(botonesGris));
    }
    // if (botonesGris.has(key)) {
    //   botonesGris.delete(key);
    // } else {
    //   botonesGris.add(key);
    // }
    // setBotonesGris(new Set(botonesGris));
  };

  useEffect(() => {
    console.log("LIST RESULTS CAMBIO: ");
    console.log(listResults);
  }, [listResults]);
  useEffect(() => {
    console.log("...dataCotizacionContext.generalsElements.generalElement");
    console.log(dataCotizacionContext.generalsElements.generalElement);
  }, [dataCotizacionContext.generalsElements.generalElement]);
  const [clickedButtons, setClickedButtons] = useState([] as number[]);

  const handleButtonClick = (index: number) => {
    // Verificar si el botón ya ha sido clickeado
    if (clickedButtons.includes(index)) {
      // Si ya ha sido clickeado, lo eliminamos de la lista de clickeados
      setClickedButtons((prevClickedButtons) =>
        prevClickedButtons.filter((buttonIndex) => buttonIndex !== index)
      );
    } else {
      // Si no ha sido clickeado, lo agregamos a la lista de clickeados
      setClickedButtons((prevClickedButtons) => [...prevClickedButtons, index]);
    }
  };
  const limpiarBotonesGris = () => {
    setBotonesGris(new Set());
  };
  const changeCantidad = (
    proveedorSelected: ServiceOP,
    cantidad: number,
    idCantidad: number
  ) => {
    if (idCantidad == 1) {
      const listResultsServicesUpdated: ServiceOP[] = listResultsServices.map(
        (currentService, index) => {
          return currentService.identificador_local !==
            proveedorSelected.identificador_local
            ? currentService
            : {
                ...currentService,
                cantidad_uno: cantidad,
              };
        }
      );

      setListResultsServices(listResultsServicesUpdated);
    } else if (idCantidad == 2) {
      const listResultsServicesUpdated: ServiceOP[] = listResultsServices.map(
        (currentService, index) => {
          return currentService.identificador_local !==
            proveedorSelected.identificador_local
            ? currentService
            : {
                ...currentService,
                cantidad_dos: cantidad,
              };
        }
      );
      setListResultsServices(listResultsServicesUpdated);
    }
  };

  const sendDesgloce = async () => {
    const servicesToSend: ServiceOP[] = listResultsServices.filter(
      (servicios, index) => {
        return servicios.cantidad_uno !== 0 && servicios.cantidad_dos !== 0;
      }
    );
    console.log("Lista filtrada de elementos  a enviarse");
    console.log(servicesToSend);
  
    const servicesToSendCorrectFormat: ServiceOPNew[] = servicesToSend.map(
      (currentService, indice) => {
        const servicio: ServiceOPNew = {
          service_identifier: currentService.service_identifier,
          fee_identifier: currentService.fee_identifier,
          quantity1: currentService.cantidad_uno,
          quantity2: currentService.cantidad_dos,
          arrival_date: paramsSearch.service_date ?? "",
          departure_date:paramsSearch.end_date ?? "",
          occupation: currentService.room_type ?? "",
        };
        return servicio;
      }
    );

    const bodyUptadeOperation: OperationNewProviders = {
      operation_identifier: currentOperation.quote.quote_identifier,
      operation_data: {
        alias: currentOperation.quote.alias,
        pax: currentOperation.quote.pax,
        sgl: currentOperation.quote.sgl,
        dbl: currentOperation.quote.dbl,
        tpl: currentOperation.quote.tpl,
        cpl: currentOperation.quote.cpl,
        adults: currentOperation.quote.adults,
        childs: currentOperation.quote.childs,
        status_identifier: 1,
      },
      services: servicesToSendCorrectFormat,
    };

    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/update`;

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyUptadeOperation),
      });

      if (response.ok) {
        const data = await response.json();

        console.log("proveedor guardado con éxito");
        console.log(data);
        console.log("Los datos se actualizaron en la siguiente operación");
        console.log(currentOperation.quote.quote_identifier);
        setContadorOperacion(contadorOperacion + 1);
        cleanInformation();
        // setShowSearchProviders(false);
        // if (data.data.provider_list) {
        //   if (data.data.provider_list.length == 0) {
        //     alert("No se encontraron coincidencias");
        //   }

        //   return;
        //   setListResults(data.data.provider_list);
        //   setListResultsProviders(data.data.provider_list_search);
        // } else {
        //   alert("No se encontraron coincidencias");
        // }
      } else {
        console.error("Error in response:", response);
        alert("Error en guardado de servicio de proveedor");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);
      alert("Error en guardado de servicio de proveedor");
      // setShowLoader(false);
    }
  };

const [numeroDeNoches, setNumeroNoches] = useState<number>(0); 
  useEffect(()=>{
    console.log("Formato de fechas");
    console.log(paramsSearch.service_date);
   
    if(paramsSearch.service_date && paramsSearch.end_date){
    setNumeroNoches(calcularNumeroDeNoches(paramsSearch.service_date,paramsSearch.end_date))
  
    
    }
    
  },[paramsSearch.service_date, paramsSearch.end_date])
  return (
    <SearchServicesStyles className="search-services">
      <div className="x-mark-box">
        {/* <FontAwesomeIcon
          icon={faXmark}
          className="x-mark"
          onClick={() => {
            setShowSearchServices(false);
          }}
        /> */}
      </div>

      {/* <Draggable> */}
      <div className="search-box-container">
        <div className="header-box-container">
          <h3>
            Busqueda {cotizationDate} {route}
          </h3>
          <FontAwesomeIcon
            icon={faXmark}
            className="x-mark"
            onClick={() => {
              setShowSearchProviders(false);
            }}
          />
        </div>

        <div className="container-box-dos">
          <div className="combo-box-features">
            <div className="list-combos">
              <div>
                <h3>Fecha inicio</h3>
                <input
                  className="date-service"
                  type="date"
                  onChange={(e) => {
                    setParamsSearch({
                      ...paramsSearch,
                      service_date: e.target.value,
                    });
                  }}
                  value={paramsSearch.service_date || ""}
                />
              </div>
              <div>
                <h3>Fecha fin</h3>
                <input
                  className="date-service"
                  type="date"
                  onChange={(e) => {
                    setParamsSearch({
                      ...paramsSearch,
                      end_date: e.target.value,
                    });
                  }}
                  value={paramsSearch.end_date || ""}
                />
              </div>
              {/* <div>
                  <h3>Tipo de proveedor</h3>
                  <SelectListdefault
                    className="select-default-dos"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                    }}
                    id="sl6"
                    onChange={(e) => {
                      setParamsSearch({
                        ...paramsSearch,
                        provider_type_identifier: parseInt(e.target.value),
                      });
                    }}
                    value={paramsSearch.provider_type_identifier || ""}
                  >
                    <option value="" disabled selected>
                      Tipo de proveedor
                    </option>

                    { providerType.map((item) => {
                      return (
                        <option
                          key={item.identifier}
                          value={item.identifier}
                          // selected={
                          //   item.provider_identifier ==
                          //   paramsSearch.service_type_identifier
                          // }
                        >
                          <h3>{item.description}</h3>
                        </option>
                      );
                    })}
                  </SelectListdefault>
                </div> */}
              <div>
                <h3>Servicio</h3>
                <SelectListdefault
                  className="select-default-dos"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    setParamsSearch({
                      ...paramsSearch,
                      service_type_identifier: parseInt(e.target.value),
                    });
                  }}
                  value={paramsSearch.service_type_identifier || ""}
                >
                  <option value="" disabled selected>
                    Servicio
                  </option>

                  {serviceList?.map((item) => {
                    return (
                      <option
                        key={item.identifier}
                        value={item.identifier}
                        selected={
                          item.identifier ==
                          paramsSearch.service_type_identifier
                        }
                      >
                        <h3>{item.description}</h3>
                      </option>
                    );
                  })}
                </SelectListdefault>
              </div>
              <div>
                <h3>Proveedor</h3>
                <SelectListdefault
                  className="select-default-dos"
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  id="sl6"
                  onChange={(e) => {
                    console.log("CATEGORIA ID SELECTED: ");
                    console.log(e.target.value);
                    setParamsSearch({
                      ...paramsSearch,
                      provider_identifier: parseInt(e.target.value),
                    });
                  }}
                  value={paramsSearch.provider_identifier || ""}
                >
                  <option value="" disabled selected>
                    Proveedor
                  </option>

                  {listResultsProviders?.length > 0
                    ? listResultsProviders.map((item) => {
                        return (
                          <option
                            key={item.provider_identifier}
                            value={item.provider_identifier}
                            // selected={
                            //   item.identifier ==
                            //   localTarifa.service_category_identifier
                            // }
                          >
                            {item.name}
                          </option>
                        );
                      })
                    : null}
                </SelectListdefault>
              </div>

              {/* {paramsSearch.service_type_identifier == 10 && (
                  <div>
                    <h3>Plan de Alimentos</h3>
                    <SelectListdefault
                      className="select-default-dos"
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                      }}
                      id="sl6"
                      onChange={(e) => {
                        setParamsSearch({
                          ...paramsSearch,
                          mealplan_identifier: parseInt(e.target.value),
                        });
                      }}
                      value={paramsSearch.mealplan_identifier || ""}
                    >
                      <option value="" disabled selected>
                        Planes
                      </option>

                      {foodPlan?.length > 0
                        ? foodPlan.map((item) => {
                            return (
                              <option
                                key={item.identifier}
                                value={item.identifier}
                                // selected={
                                //   item.identifier ==
                                //   localTarifa.service_category_identifier
                                // }
                              >
                                {item.description}
                              </option>
                            );
                          })
                        : null}
                    </SelectListdefault>
                  </div>
                )} */}
            </div>
          </div>

          <div className="search-container">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                limpiarBotonesGris();
                if (
                  paramsSearch.search_parameter != "" ||
                  paramsSearch.service_type_identifier !== null ||
                  paramsSearch.category_identifier != null ||
                  paramsSearch.city_identifier != null
                ) {
                  searchProveedores();
                  console.log("ENTRASTE A BUSCAR");
                } else {
                  alert("Introduce más terminos por favor.");
                }
                console.log("PARAMS DESDE SUBMIT");
                console.log(paramsSearch);
              }}
            >
              <input
                type="text"
                value={paramsSearch.search_parameter}
                onChange={(e) => {
                  setParamsSearch({
                    ...paramsSearch,
                    search_parameter: e.target.value,
                  });
                }}
                className="input-search"
              />

              <button
                onClick={() => {
                  //
                  // addNewServicio(idCurrentDayService)
                }}
              >
                Buscar
              </button>
            </form>
            {/* {paramsSearch.service_type_identifier && (
              <div className="encabezado-busqueda">
                <div></div>
                <div className="encabezado-titulos">
                  <h3>
                    {paramsSearch.service_type_identifier == 10
                      ? "Acomodo"
                      : paramsSearch.service_type_identifier == 18
                      ? "Cantidad"
                      : "No PAX"}
                  </h3>
                  <h3>
                    {paramsSearch.service_type_identifier == 10
                      ? "Noches"
                      : paramsSearch.service_type_identifier == 18
                      ? "Servicio"
                      : "Cantidad"}
                  </h3>
                </div>
              </div>
            )} */}

            <div className="search-results">
              {listResultsServices?.map((proveedor, index) => {
                // const proveedorUsed:ServiceOP = numeroDeNoches == 0 ? proveedor : {
                //   ...proveedor,
                //   cantidad_dos: numeroDeNoches
                // };

                return (
                  <>
                    <div className="encabezado-busqueda">
                      <div></div>
                      <div className="encabezado-titulos">
                        <h3>
                          {proveedor.service_type_identifier == 10
                            ? "Acomodo"
                            : proveedor.service_type_identifier == 18
                            ? "Cantidad"
                            : "No PAX"}
                        </h3>

                        <h3>
                          {proveedor.service_type_identifier == 10
                            ? "Noches"
                            : proveedor.service_type_identifier == 18
                            ? "Servicio"
                            : "Cantidad"}
                        </h3>
                      </div>
                    </div>
                    <SearchServiceElement
                      proveedor={proveedor}
                      changeCantidad={changeCantidad}
                      key={proveedor.identificador_local}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="deleteIcon-box">
        <FontAwesomeIcon
          className="deleteIcon"
          icon={faBroom}
          onClick={cleanInformation}
        />
          <FontAwesomeIcon
          className="deleteIcon save-icon"
          icon={faSave}
          onClick={sendDesgloce}
        />
      </div>
      {/* </Draggable> */}
    </SearchServicesStyles>
  );
}

const SearchServicesStyles = styled.div`
.save-icon{
  margin-left: 1rem; 
  background-color: rgb(8, 166, 165) !important;
}
  .input-search {
    width: 80%;
  }
  .encabezado-busqueda {
    display: grid;
    grid-template-columns: 3fr 1fr;

    grid-column-gap: 2rem;
  }
  .encabezado-titulos {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .encabezado-busqueda div h3 {
    font-size: 0.85vw;
    color: rgb(8, 166, 165);
  }
  .boton-desgoce {
    width: 30%;
    justify-self: right;
    margin-right: 2rem;
    border: none;
    background-color: rgb(8, 166, 165);
    color: white;
    border-radius: 0.2rem;
    border: rgb(8, 166, 165) solid 1px;
    transition: all 0.4s linear;
    cursor: pointer;
  }

  .boton-desgoce:hover {
    background-color: white;
    color: rgb(8, 166, 165);
    border-radius: 0.2rem;

    border: rgb(8, 166, 165) solid 1px;
  }
  .date-service {
    padding: 0.3rem;
  }
  .title-servicio {
    color: rgb(8, 166, 165);
    font-weight: 600 !important;
  }
  .money-info {
    font-weight: 600 !important;
  }
  .element-container h3 {
    font-size: 0.85vw;
  }
  .container-data-info {
    border-bottom: grey solid 2px;
  }
  .container-data-info h3 {
    margin-bottom: 0.5rem;
  }
  .element-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 0.9rem;
    grid-column-gap: 2rem;
  }
  .container-inputs {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .container-inputs input {
    width: 30%;
    max-width: 30%;
    outline: none;
    /* border: black solid 1px; */
    text-align: center;
  }
  .deleteIcon-box {
    position: fixed;
  }
  .list-combos {
    /* border: red solid 1px; */
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  h3 {
    font-weight: 400;
  }
  .provider-style {
    font-weight: 600;
  }
  .gris {
    background-color: grey;
    border: none;
    color: white;
    font-size: 0.6vw;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.4s ease-out;
    border: grey;
    padding: 0.2rem;
  }
  form {
    width: 100%;
  }
  .search-bar {
    display: flex;
  }
  .search-bar input {
    width: 80%;
  }
  .container-search-term {
    border-bottom: grey solid 1px;
    margin-bottom: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .vigencia-box {
  }
  .vigencia-box h3 {
    font-size: 0.85vw;
  }

  @media screen and (max-width: 900px) {
    .vigencia-box h3 {
      font-size: 1.5vw;
    }
  }
  .deleteIcon {
    margin-top: 2rem;
    font-size: 1.2rem;
    border-radius: 50%;
    padding: 0.6rem;
    cursor: pointer;
    background-color: #29a5dc;
    color: white;
  }

  .select-button {
    border: none;
    background-color: rgb(8, 166, 165);
    color: white;
    font-size: 0.6vw;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.4s ease-out;
    border: rgb(8, 166, 165) solid 1px;
    padding: 0.2rem;
    max-height: 1.4rem;
  }
  .provider-style {
    color: rgb(8, 166, 165);
  }
  .select-button:hover {
    color: rgb(8, 166, 165);
    background-color: white;
    border: rgb(8, 166, 165) solid 1px;
  }
  .search-term {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .search-term-habitacion {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0.2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .search-term h3,
  .search-term-habitacion h3 {
    font-size: 0.86vw !important;
  }
  @media screen and (max-width: 900px) {
    .search-term h3,
    .search-term-habitacion h3 {
      font-size: 1.6vw !important;
    }
    .select-button {
      font-size: 1.2vw !important;
    }
    .combo-box-features h3 {
      font-size: 1.6vw !important;
    }
  }
  .search-results {
    height: 85%;
    overflow-y: scroll;
    margin-top: 1rem;
  }
  .combo-box-features {
    width: 100%;
    min-width: 100%;
    /* border: red solid 1px; */
    display: flex;
    flex-direction: column;
  }
  .combo-box-features div div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    /* border: black solid 1px; */
    font-size: 0.85vw !important;
  }
  .combo-box-features div h3 {
    text-align: center;
  }

  .container-box-dos {
    margin-top: 1rem;
    overflow-y: hidden;
    max-height: 80%;
    min-height: 80%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    /* border: blue solid 1px; */
    padding: 0 1rem;
  }
  .search-container {
    width: 100%;
    min-width: 100%;
    margin: 0 auto;
    /* border: grey solid 1px; */
    min-height: 40vh;

    padding: 0.8rem;
  }
  .combo-box-features h3 {
    font-size: 0.85vw;
  }
  .header-box-container {
    margin-top: 1rem;
    margin-left: 1rem;

    display: flex;
    justify-content: space-between;
  }
  .header-box-container h3 {
    color: rgb(8, 166, 165);
  }
  .combo-box-directions {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(211, 211, 211, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 101;
  padding: 50px 0px;
  .x-mark-box {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 20%;
    padding-top: 15%;
    position: fixed;
    top: -50px;
    right: -10px;
  }
  .x-mark {
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 1rem;
  }
  .search-box-container {
    width: 50%;
    height: 600px;
    border: rgb(8, 166, 165) solid 2px;
    border-radius: 1.2rem;
    background-color: white;
    position: absolute;
    top: calc(50% - 300px);
  }
  @media screen and (max-width: 900px) {
    .search-box-container {
      width: 80%;
    }
  }
`;

const SelectListdefault = styled.select`
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: none;
  color: "#000000";
  cursor: pointer;
  outline: none;
  font-size: 0.85vw;
  align-items: center;
  max-height: 38.4px !important;
  border: black solid 1px;
  @media screen and (max-width: 900px) {
    font-size: 1.6vw;
  }
`;

function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength) + "...";
  }
}
