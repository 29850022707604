import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  PendingPayment,
  ProviderContabilidadI,
} from "../InterfacesContabilidad";
import { OperationContext } from "../../../../context/operationContext";
interface paymentI {
  payment: PendingPayment;
  providerInfo: ProviderContabilidadI;
  listPayments: PendingPayment[];
  setListPayments: React.Dispatch<React.SetStateAction<PendingPayment[]>>;
}
export default function ElementPayment({
  payment,
  providerInfo,
  listPayments,
  setListPayments,
}: paymentI) {
  const { currentOperation } = useContext(OperationContext);
  const [localPayment, setLocalPayment] = useState<PendingPayment>(payment);
  const [inicialData, setInitialData] = useState<ProviderContabilidadI>(providerInfo);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (payment.payment_status == "Pagado") {
      return;
    }
    setLocalPayment({
      ...localPayment,
      isSelected: event.target.checked,
    });
    const newArregloPayments: PendingPayment[] = listPayments.map(
      (currentPayment, index) => {
        const pagoActual: PendingPayment = {
          ...currentPayment,
          isSelected:
            currentPayment.id_local != payment.id_local
              ? currentPayment.isSelected
              : event.target.checked,
        };
        return pagoActual;
      }
    );

    setListPayments(newArregloPayments);

    const seleccionados = newArregloPayments
      .filter((fila) => fila.isSelected)
      .reduce((acc, fila) => acc + fila.advance_mxn, 0);

    providerInfo.amount_money = seleccionados;
  };

  return (
    <ElementPaymentStyle>
      <div
        className="element-table"
        style={{
          background: localPayment.payment_status == "Pagado" ? "#DBFCDB" : ""
        }}
      >
        <div className="check-box">
          <input
            type="checkbox"
            checked={localPayment.isSelected}
            onChange={handleCheckboxChange}
          />
        </div>
        <div>
          <h3>{localPayment.provider_name}</h3>
        </div>
        <div>
          <h3>{inicialData.key}</h3>
        </div>
        <div>
          <h3>{localPayment.entry_number}</h3>
        </div>
        <div>
          <h3>${localPayment.advance_mxn.toFixed(2)}</h3>
        </div>
        <div>
          <h3>${localPayment.advance_usd.toFixed(2)}</h3>
        </div>
        <div>
          <h3>{currentOperation.quote.name}</h3>
        </div>
        <div>
          <h3>{localPayment.payday_limit}</h3>
        </div>
        <div>
          <h3>{localPayment.initial_date}</h3>
        </div>
        <div>
          <h3>{localPayment.final_date}</h3>
        </div>
      </div>
    </ElementPaymentStyle>
  );
}

const ElementPaymentStyle = styled.div`
  .check-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .element-table {
    border: black solid 1px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    padding: 0.55rem;
  }
  .element-table div h3 {
    font-size: 0.85vw;
    text-align: center;
    font-weight: 300;
  }
`;
