import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { fetchServer } from "../../../services/fetchServer";
import { AppContext } from "../../../context/appContext";
import { clientInterface } from "../../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { GeneralsData, bodyResponseProvider } from "../../../interfaces/ProviderTypes";
import deletelogo from "../../../images/deletelogo.png";
import { DeleteProveedor } from "../../../interfaces/DeleteTypes";
import { GeneralDeleteI, GeneralModifiedI } from "../../../interfaces/GuardadoTypes";
import DeleteConfirmation from "../common/DeleteConfirmation";
interface elementClientInterface {
  deleteProvider: (idCurrentProveedor: number) => Promise<void>
}

export default function ElementClient({ clientInfo, deleteProvider }: clientInterface & elementClientInterface) {
  const { showInfoContact, setShowInfoContact, setContactCurrentInfo,contactCurrentInfo, setIdCurrentProvider } =
    useContext(AppContext);
  const {
    business_name,
    city,
    country,
    identifier,
    name,
    neighborhood,
    number,
    observations,
    provider_type,
    registration_type,
    status,
    street,
    zipcode,
  } = clientInfo;
  useEffect(()=>{
    console.log('INFORMACION DE CONTACTO: ');
    console.log(clientInfo); 
  },[clientInfo])

  const [generalData, setGeneralData] = useState<GeneralsData>();

  const [isShownDelete, setIsShownDelete] = useState<boolean>(false); 
  const handleDeleteClick = () => {
    setIsShownDelete(true);
  };

  const handleDeleteConfirm = () => {
    deleteProvider(clientInfo.identifier); 
    setIsShownDelete(false);
////BORRADO
  };

  const handleDeleteCancel = () => {
    setIsShownDelete(false);
  };
  
  return (
<>
<ElementClientStyles>
      <div className="client-element">
        <h4>{name}</h4>
        <div className="container-buttons">
        <Link
        to="/dashboard/Providers-Info" className="link link-item"
        >
        <button
          className="btn-detalle"
          onClick={() => {
            
            setContactCurrentInfo({
              clientInfo,
            });
            // setShowInfoContact(true);
            setIdCurrentProvider(clientInfo.identifier);

          }}
        >
          Ver más
        </button>
        </Link>
        <img src={deletelogo} alt="borrado" 
         onClick={handleDeleteClick}
         width={25}
         height={25}
         className="delete-logo-c"
        />
        {/* <button
       
        className="link link-item">Borrar</button> */}
        </div>
      
      
      </div>
    </ElementClientStyles>
    {
    isShownDelete &&  <DeleteConfirmation
    onDelete={handleDeleteConfirm} onCancel={handleDeleteCancel} itemName={`¿Deseas borrar el contacto ${name}?`}
    setIsShownDelete = {setIsShownDelete}
    />
   }
</>
  );
}

const ElementClientStyles = styled.div`
.container-buttons{
  display: flex;
  justify-content: center;
align-items: center;
}
.delete-logo-c{
  cursor: pointer; 
}
.link-item{
  margin-right: 1rem;
}
  .client-element {
    border-radius: 0.4rem;
    width: 60%;
    background-color: white;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.3rem;
  }
  .client-element h4 {
    color: rgb(8, 166, 165);
  }
  .btn-detalle {
    border: none;
    background-color: transparent;
    background-color: rgb(8, 166, 165);
    padding: 0.4rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;
    color: white;
  }
`;
