import React from 'react'; 
import styled from "styled-components";
import { Invoice, ProformaDataI } from './AuxiliaresInterface';
import { formatearCantidad } from '../../../components/functionsTs/utilidades';
import editButton from '../../../../images/edit.png';
import deleteButton from '../../../../images/deletelogo.png'; 


interface TableAuxiliarElementI{
    currentInvoice: Invoice,
    auxiliar: ProformaDataI,
    deleteInvoice: (currentInvoice: Invoice) => void,
    editInvoice: (currentInvoice: Invoice) => void,
    setModoEdicion: React.Dispatch<React.SetStateAction<boolean>>,
    modoEdicion: boolean,
    currentInvoiceToModify: Invoice
}
const fechaActual: Date = new Date();
// Obtiene los componentes de la fecha
const año: number = fechaActual.getFullYear();
const mes: number = fechaActual.getMonth() + 1; // ¡Recuerda que los meses comienzan desde 0!
const dia: number = fechaActual.getDate();

// Formatea la fecha en el formato deseado
const fechaFormateada: string = `${año}-${mes < 10 ? "0" : ""}${mes}-${
  dia < 10 ? "0" : ""
}${dia}`;

export default function TableAuxiliarElement({currentInvoice, auxiliar, deleteInvoice, editInvoice, setModoEdicion, modoEdicion, currentInvoiceToModify}:TableAuxiliarElementI) {
    const borrarElemento = (currentInvoice: Invoice) =>{
        deleteInvoice(currentInvoice); 
    }
    const editarElemento = (currentInvoice: Invoice) =>{
        setModoEdicion(true); 
        editInvoice(currentInvoice); 
    }
  return (
    <TableAuxiliarElementStyle>
        <div className='element-invoice'
        style={{
            backgroundColor: modoEdicion && ( currentInvoiceToModify.invoice_local_id == currentInvoice.invoice_local_id) ? 'orange':''
        }}
        >
            <div>
                <h3>{fechaFormateada}</h3>
            </div>
            <div>
                <h3>{currentInvoice.invoice_number}</h3>
            </div>
            <div>
                <h3>${formatearCantidad(auxiliar.exchange_rate)}</h3>
            </div>
            <div>
                <h3>${formatearCantidad(currentInvoice.subtotal_invoiced)}</h3>
            </div>
            <div>
                <h3>${formatearCantidad(currentInvoice.iva)}</h3>
            </div>
            <div>
                <h3>${formatearCantidad(currentInvoice.total_invoiced)}</h3>
            </div>
            <div>
                <h3>{currentInvoice.invoice_type_identifier == 2 ? "Anticipo": "Liquidación"}</h3>
            </div>
            
            <div>
                <h3>${formatearCantidad(currentInvoice.advance)}</h3>            
            </div>
            <div className='icon-container'>
            <img src={editButton} alt="" width={25} onClick={()=>editarElemento(currentInvoice)}/>
                <img src={deleteButton} alt="" width={25}
                onClick = {()=>borrarElemento(currentInvoice)}
                />
            </div>
        </div>
    </TableAuxiliarElementStyle>
  )
}

const TableAuxiliarElementStyle = styled.div`
    .element-invoice{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;
        padding: 0.5rem;    
        border: black solid 1px;
        border-top:none;         

    }
    .icon-container{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .element-invoice div{
        
        
    }
    img{
        cursor: pointer; 
    }
    .element-invoice div h3{
        font-size: 0.85vw;        
        text-align: center;
    }
    h3 {
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 30em;
  }
`