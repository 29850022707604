import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import WholeSearch from "./WholeSearch";

const printDocument = () => {
  window.print();
};

export default function ContabilidadSearch() {
  return (
    <ContabilidadSearchStyles>
      <br />
      <br />
      <WholeSearch />
      <br />
      <br />
      <div className="non-printable-section">
        <div className="print-box">
          <button onClick={printDocument}>Imprimir</button>
        </div>
      </div>
    </ContabilidadSearchStyles>
  );
}

const ContabilidadSearchStyles = styled.div``;
