import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { formatearCantidad } from "../functionsTs/utilidades";

interface Props<T> {
  field: string;
  text: string;
  txtWidth: number;
  value?: number;
  setGeneralData: React.Dispatch<React.SetStateAction<T>>;
  onChange?: (value: string) => void;
  typeData: string; 
  isEditableBox?:  boolean;
  extraFunction?: () =>void; 
  isNegativeValid?: number; 
}
interface styledInputProps {
    percentageWidth: number;
  }
function CustomInputMoney<T>(props: Props<T>) {
  const [inputValue, setInputValue] = useState(props.value || '');
  const isScrollingRef = useRef<boolean>(false);
  const obtenerValor = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(props.isEditableBox !== null){
      if(props.isEditableBox == false){
        return; 
      }
    }
    if(parseFloat(event.target.value) < 0 && props.isNegativeValid != 1){
      return;
    }
    const value = event.target.value;
    setInputValue(value);
    props.setGeneralData((prevProvider) => ({
      ...prevProvider,
      [props.field]: parseFloat(value),
    }));

    if (props.onChange) {
      props.onChange(value);
    }
  };
  useEffect(()=>{
  
    setInputValue(props.value || "");
  },[props.value])
  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    if (!isScrollingRef.current) {
      isScrollingRef.current = true;
      event.currentTarget.blur(); // Desenfoca la entrada para evitar el cambio de valor durante el desplazamiento
      setTimeout(() => {
        isScrollingRef.current = false;
        event.currentTarget.focus(); // Vuelve a enfocar la entrada después del desplazamiento
      }, 200); // Ajusta el tiempo según sea necesario para asegurarte de que se haya completado el desplazamiento
    }
    
    console.log('EVENT WHEEL'); 
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
   
      if(props.extraFunction){
        props.extraFunction(); 
      }
      
      // console.warn(props.variableNueva);
      // console.log(props); 
      // if(props.variableNueva){
    
      //   console.log(props.variableNueva);
      // }
     
    }
  };
  
  return (
    <div style={{width:  props.txtWidth + "%", maxWidth: props.txtWidth + "%"}}>
<PrincipalContainer  style={{width:  "100%", maxWidth: "100%"}}>
        <EntryContainer>
          <Entry
            id={props.field}
            type={props.typeData}
            // placeholder={props.text}
            style={{
              width: "100%",
            }}
            inputMode="decimal"
            onChange={obtenerValor}
            value={inputValue}
            
            autoComplete="off"
            className={inputValue ? "has-value input-style-data" : "input-style-data"}
            onWheel={handleWheel}
            onKeyDown={(e)=>{
              handleKeyDown(e); 
            }}
          />
          <Label htmlFor={props.field}>{props.text}</Label>
          {
            inputValue &&     <h3 className="mask">$ {formatearCantidad(typeof inputValue == "number" ? parseFloat(inputValue.toFixed(2)): 0)}</h3>
          }
      
        </EntryContainer>
      </PrincipalContainer>
    </div>
      

  );
}

export default CustomInputMoney;

const Entry = styled.input`

  background-color: #ffffff;
  border-style: none;
  border-bottom: 2px solid #b1b1b1;
  color: "#000000";
  cursor: text;
  outline: none;
  border: solid rgb(8, 166, 165) 2px;
  input {
  position: relative;
}


 
 
  font-size: 0.85vw;
  padding: 0.5rem 0.5rem;
  padding-left: 0.8rem;
  position: relative;
  z-index: 1;
  min-height: 38.4px;
  @media screen and (max-width: 900px){
    font-size: 1.6vw;
  }
  &::placeholder:not(.has-value) {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    font-size: 1rem;
    color: #b1b1b1;
    pointer-events: none;
    transition: all 0.2s ease-out;
    z-index: 1;
  }
  .input-style-data::-webkit-inner-spin-button,
.input-style-data::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-style-data{
    border: black solid 1px; 
}
`;

const PrincipalContainer = styled.div`

  height: auto;
  display: flex;

`;

const EntryContainer = styled.div`

.mask{ 
    position: absolute;
    top: 0.7rem;
    z-index: 1000;
    font-size: 0.85vw;
    left: 0.1rem;
    font-weight: 300;

    background-color: red;
 min-width   : 100%;
 background-color: transparent;
 z-index: -1;
    /* background-color: white;
    width: 90%; */
}
input{
    color: transparent !important;
    caret-color: black;  
    background-color: transparent;
}
input:focus {
  outline: 1px solid tomato;
}
  width: 100%;
  position: relative;
  max-width: 100%;
`;

const Label = styled.label`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-size: 12px;
  color: rgb(8, 166, 165);
  pointer-events: none;
  transition: all 0.2s ease-out;
  z-index: 1;
  background-color: white;
`;