import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import RowCotizacion from "./Components/RowCotizacion";
import {
  ApiResponseListProviders,
  BankAccountIGuia,
  ContactIGuia,
  OperationNewProviders,
  QuoteInterface,
} from "./InterfacesOperacion/OperacionesI";
import editboton from "../../../images/edit.png";
import { OperationContext } from "../../../context/operationContext";
import guardardatos from "../../../images/GUARDARDATOS.png";
interface headerI {
  bankListGuia?: BankAccountIGuia[];
  contactGuiaList?: ContactIGuia[];
  nombreNegocio?: string;
  statusOperacion?: number;
  setStatusOperacion?: React.Dispatch<React.SetStateAction<number>>;
}
export default function HeaderOpRead({
  bankListGuia,
  contactGuiaList,
  nombreNegocio,
  statusOperacion,
  setStatusOperacion,
}: headerI) {
  const { dataCotizacionContext, idOperation } = useContext(AppContext);
  const { currentOperation, setCurrentOperation } =
    useContext(OperationContext);

  const [quoteInterfaceData, setQuoteInterfaceData] = useState<QuoteInterface>({
    quote_identifier: 0,
    name: "",
    alias: "",
    agency: "",
    arrival_date: "",
    departure_date: "",
    pax: 0,
    sgl: 0,
    dbl: 0,
    tpl: 0,
    cpl: 0,
    adults: 0,
    childs: 0,
  });

  const getProviderList = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;

    const urlModified =
      backEndPoint + `/operations/getQuoteProviders/${idOperation}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        // console.log(response);
        // console.log("bien");
        const data: ApiResponseListProviders = await response.json();

        setQuoteInterfaceData(data.data.quote);
        if (statusOperacion == 0 || statusOperacion) {
          if (data.data.quote.status_identifier) {
            if (setStatusOperacion !== undefined) {
              // Ahora puedes invocar setStatusOperacion de forma segura

              setStatusOperacion(data.data.quote.status_identifier);
            } else {
              // Manejar el caso donde setStatusOperacion es undefined
              console.error("setStatusOperacion es undefined");
            }
          }
        }
        // console.log("Datos de operación");
        // console.log(data.data);
        // console.log(
        //   "-------------------------------------------LISTA DE OPERACIONES:::::"
        // );
        // console.log("ID DE OPERACION:::");
        // console.log(idOperation);
        // console.log(data.data.providers);

        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");
      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
    }
  };
  const [isEditionMode, setIsEditionMode] = useState<boolean>(false);
  useEffect(() => {
    getProviderList();
  }, []);
  const saveGeneralDataOperacion = async () => {
    const bodyUptadeOperation: OperationNewProviders = {
      operation_identifier: currentOperation.quote.quote_identifier,
      operation_data: {
        alias: quoteInterfaceData.alias,
        pax: quoteInterfaceData.pax,
        sgl: quoteInterfaceData.sgl,
        dbl: quoteInterfaceData.dbl,
        tpl: quoteInterfaceData.tpl,
        cpl: quoteInterfaceData.cpl,
        adults: quoteInterfaceData.pax,
        childs: quoteInterfaceData.childs,
        status_identifier: 1,
      },
      services: [],
    };

    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/update`;

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyUptadeOperation),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Datos guardados / actualizados:");
        // console.log("proveedor guardado con éxito");
        // console.log(data);
        // console.log("Los datos se actualizaron en la siguiente operación");
        // console.log(currentOperation.quote.quote_identifier);
        // setContadorOperacion(contadorOperacion + 1);
        // cleanInformation();
        // setShowSearchProviders(false);
        // if (data.data.provider_list) {
        //   if (data.data.provider_list.length == 0) {
        //     alert("No se encontraron coincidencias");
        //   }

        //   return;
        //   setListResults(data.data.provider_list);
        //   setListResultsProviders(data.data.provider_list_search);
        // } else {
        //   alert("No se encontraron coincidencias");
        // }
      } else {
        console.error("Error in response:", response);
        alert("Error en guardado de servicio de proveedor");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);
      alert("Error en guardado de servicio de proveedor");
      // setShowLoader(false);
    }
  };

  return (
    <HeaderInfoStyles>
      <div className="container-alias">
        <div className="alias-box">
          <h3>Alias</h3>
          {isEditionMode ? (
            <input
              type="text"
              onChange={(e) => {
                setQuoteInterfaceData({
                  ...quoteInterfaceData,
                  alias: e.target.value,
                });
              }}
              value={quoteInterfaceData.alias}
            />
          ) : (
            <h3>{quoteInterfaceData.alias}</h3>
          )}
        </div>
      </div>

      <div className="container-info-header-one">
        <div>
          <h3>Nombre de cotización</h3>

          <h3 className="sub">{quoteInterfaceData.name}</h3>
        </div>
        <div>
          <h3>Fecha de llegada</h3>
          <h3>{quoteInterfaceData.arrival_date}</h3>
        </div>
        <div>
          <h3>Fecha de salida</h3>
          <h3>{quoteInterfaceData.departure_date}</h3>
        </div>
        <div>
          <h3>Agencia</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                setQuoteInterfaceData({
                  ...quoteInterfaceData,
                  agency: e.target.value,
                });
              }}
              value={quoteInterfaceData.agency}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.agency}</h3>
          )}
        </div>
      </div>
      <div className="container-info-header-two">
        <div>
          <h3>Temporada</h3>
          <h3 className="sub"></h3>
        </div>
        <div>
          <h3>Número pax</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    pax: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      pax: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.pax}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.pax}</h3>
          )}
        </div>
        <div>
          <h3>SGL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    sgl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      sgl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.sgl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.sgl}</h3>
          )}
        </div>
        <div>
          <h3>DBL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    dbl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      dbl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.dbl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.dbl}</h3>
          )}
        </div>
        <div>
          <h3>TPL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    tpl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      tpl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.tpl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.tpl}</h3>
          )}
        </div>
        <div>
          <h3>CPL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    cpl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      cpl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.cpl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.cpl}</h3>
          )}
        </div>
        <div>
          <h3>Adultos</h3>

          <h3 className="sub">{quoteInterfaceData.pax}</h3>
        </div>
        <div>
          <h3>Menores</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    childs: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      childs: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.childs}
            />
          ) : (
            <h3 className="sub"> {quoteInterfaceData.childs}</h3>
          )}
        </div>
      </div>

      <div className="opcional">
        {nombreNegocio != "" && (
          <div className="container-negocio">
            <h3>
              {nombreNegocio?.trim() ? "Razón social: " : ""}{" "}
              <span className="nombre-negocio-show">{nombreNegocio}</span>
            </h3>
          </div>
        )}

        {(bankListGuia?.length ?? 0) > 0 && (
          <h3 className="title-accounts">Cuenta(s) de banco:</h3>
        )}

        {bankListGuia?.map((cuentaBanco, index) => {
          return (
            <>
              <div className="container-bank-accounts">
                <h3>{cuentaBanco.bank}</h3>
                <h3>{cuentaBanco.bank_account}</h3>
                <h3>{cuentaBanco.currency_identifier == 1 ? "USD" : "MXN"}</h3>
              </div>
            </>
          );
        })}
        {(contactGuiaList?.length ?? 0) > 0 && (
          <div className="container-email-dos">
            <h3>Email: </h3>
          </div>
        )}
        {contactGuiaList?.map((guia, index) => {
          return (
            <>
              <div className="container-email">
                <h3>{guia.contact_email}</h3>
              </div>
            </>
          );
        })}
      </div>
    </HeaderInfoStyles>
  );
}

const HeaderInfoStyles = styled.div`
  border: rgb(8, 166, 165) solid 2px;
  @media print {
    border: black  solid 1px;
  }
  padding: 0.8rem;
  padding-bottom: 0.4rem;
  .container-email-dos {
    margin-top: 1rem;
  }
  .container-email-dos h3 {
    font-size: 0.85vw;
  }
  .container-negocio h3 {
    font-size: 0.85vw;
    margin-bottom: 1.2rem;
  }
  .nombre-negocio-show {
    font-weight: 300;
  }
  .container-bank-accounts {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
  .container-email {
    margin-bottom: 1rem;
    margin-top: 1.2rem;
  }
  .container-email h3 {
    font-size: 0.85vw;
    font-weight: 300;
  }
  .title-accounts {
    font-size: 0.85vw;
    margin-bottom: 1rem;
  }
  .container-bank-accounts h3 {
    font-weight: 300 !important;
    font-size: 0.85vw;
  }
  .container-alias {
    display: flex;
    align-items: center;
  }
  .container-alias img {
    margin-left: 1rem;
    cursor: pointer;
  }

  .input-info {
    width: 60%;
    text-align: center;
  }
  .alias-box {
    display: flex;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    align-items: center;
  }
  .alias-box h3 {
    margin-right: 1rem;
    font-size: 0.85vw;
  }
  .alias-box input {
    padding: 0.5rem;
    font-size: 0.85vw;
    outline: none;
    border: grey solid 1px;
  }
  .container-info-header-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .container-info-header-one div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    align-items: center;
  }
  .container-info-header-one h3 {
    text-align: center;
    font-size: 0.85vw;
    margin-bottom: 0.4rem;
  }

  .container-info-header-two {
    padding: 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .container-info-header-two div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container-info-header-two h3 {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 0.85vw;
  }
  .sub {
    font-weight: 300;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 900px) {
    .container-info-header-two h3 {
      text-align: center;
      font-size: 1.1vw;
    }
    .container-info-header-one h3 {
      text-align: center;
      font-size: 1.3vw;
    }
    .alias-box h3 {
      font-size: 1.3vw;
    }
    .alias-box input {
      font-size: 1.3vw;
    }
    .container-info-header-one h3 {
      font-size: 1.3vw;
    }
    .container-info-header-two h3 {
      font-size: 1.3vw;
    }
  }
`;
