import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../context/appContext";
import RowCotizacion from "./Components/RowCotizacion";
import {
  ApiResponseListProviders,
  OperationNewProviders,
  QuoteInterface,
  BankAccountIGuia,
} from "./InterfacesOperacion/OperacionesI";
import editboton from "../../../images/edit.png";
import { OperationContext } from "../../../context/operationContext";
import guardardatos from "../../../images/GUARDARDATOS.png";

interface HeaderOpEditI {
  bankListGuia?: BankAccountIGuia[];
  statusOperacion: number;
  setStatusOperacion: React.Dispatch<React.SetStateAction<number>>;
}

export default function HeaderOpEdit({
  bankListGuia,
  statusOperacion,
  setStatusOperacion,
}: HeaderOpEditI) {
  const { dataCotizacionContext, idOperation, setShowLoader, currentUser } =
    useContext(AppContext);
  const { currentOperation, setCurrentOperation } =
    useContext(OperationContext);
  const [baseInicialPax, setBaseInicialPax] = useState<number>(0);
  const [quoteInterfaceData, setQuoteInterfaceData] = useState<QuoteInterface>({
    quote_identifier: 0,
    name: "",
    alias: "",
    agency: "",
    arrival_date: "",
    departure_date: "",
    pax: 0,
    sgl: 0,
    dbl: 0,
    tpl: 0,
    cpl: 0,
    adults: 0,
    childs: 0,
  });

  const getProviderList = async () => {
    const backEndPoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    setShowLoader(true);
    const urlModified =
      backEndPoint + `/operations/getQuoteProviders/${idOperation}`;

    try {
      const response: Response = await fetch(urlModified, {
        method: "GET", // Cambio del método POST a GET
        headers: {
          "Content-Type": "application/json",
        },
        // Eliminar el cuerpo ya que las peticiones GET no llevan body
      });

      if (response.ok) {
        ////RESPUESTA CORRECTA
        console.log(response);
        console.log("bien");
        const data: ApiResponseListProviders = await response.json();

        setQuoteInterfaceData(data.data.quote);
        setBaseInicialPax(data.data.quote.pax);
        setStatusOperacion(data.data.quote.status_identifier ?? 0);
        console.log("Datos de operación");
        console.log(data.data);
        console.log(
          "-------------------------------------------LISTA DE OPERACIONES:::::"
        );
        console.log("ID DE OPERACION:::");
        console.log(idOperation);
        console.log(data.data.quote.status_identifier);

        setShowLoader(false);
        // navigate("/dashboard/Alta-Cotizacion/Registro-Cotizacion");
      } else {
        ///RESPUESTA INCORRECTA DE SERVICIOS
        // setShowLoader(false);
        // setTitleAlert("Error al realizar la solicitud!:");
        // setContentAlert(
        //   "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
        // );
        // setShowAlert(true);
        setShowLoader(false);
      }
    } catch (error) {
      ///ERROR AL REALIZAR LA PETICION
      //   alert("ERROR AL REALIZAR LA SOLICITUD");
      //   setTitleAlert("Error al realizar la solicitud:");
      //   setContentAlert(
      //     "Los datos no fueron obtenidos correctamente, por favor, verifica tu solicitud."
      //   );
      setShowLoader(false);
    }
  };
  const [isEditionMode, setIsEditionMode] = useState<boolean>(false);
  useEffect(() => {
    getProviderList();
  }, []);
  const saveGeneralDataOperacion = async () => {
    setShowLoader(true);
    const bodyUptadeOperation: OperationNewProviders = {
      operation_identifier: currentOperation.quote.quote_identifier,
      operation_data: {
        alias: quoteInterfaceData.alias,
        pax: quoteInterfaceData.pax,
        sgl: quoteInterfaceData.sgl,
        dbl: quoteInterfaceData.dbl,
        tpl: quoteInterfaceData.tpl,
        cpl: quoteInterfaceData.cpl,
        adults: quoteInterfaceData.pax,
        childs: quoteInterfaceData.childs,
        status_identifier: 1,
      },
      services: [],
    };

    const loginUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/operations/update`;

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyUptadeOperation),
      });

      if (response.ok) {
        const data = await response.json();

        setIsEditionMode(false);
        // console.log("proveedor guardado con éxito");
        // console.log(data);
        // console.log("Los datos se actualizaron en la siguiente operación");
        // console.log(currentOperation.quote.quote_identifier);
        // setContadorOperacion(contadorOperacion + 1);
        // cleanInformation();
        // setShowSearchProviders(false);
        // if (data.data.provider_list) {
        //   if (data.data.provider_list.length == 0) {
        //     alert("No se encontraron coincidencias");
        //   }

        //   return;
        //   setListResults(data.data.provider_list);
        //   setListResultsProviders(data.data.provider_list_search);
        // } else {
        //   alert("No se encontraron coincidencias");
        // }
        setCurrentOperation({
          ...currentOperation,
          quote: {
            ...currentOperation.quote,
            alias: quoteInterfaceData.alias,
            pax: quoteInterfaceData.pax,
            sgl: quoteInterfaceData.sgl,
            dbl: quoteInterfaceData.dbl,
            tpl: quoteInterfaceData.tpl,
            cpl: quoteInterfaceData.cpl,
            adults: quoteInterfaceData.adults,
            childs: quoteInterfaceData.childs,
          },
        });
        setShowLoader(false);
      } else {
        console.error("Error in response:", response);
        alert("Error en guardado de servicio de proveedor");

        // setTitleAlert('Error al iniciar sesión');
        // setContentAlert('Por favor, digite un email y contraseña valido.');
        // setShowAlert(true);
        setShowLoader(false);
        setCurrentOperation({
          ...currentOperation,
          quote: {
            ...currentOperation.quote,
            alias: quoteInterfaceData.alias,
            pax: quoteInterfaceData.pax,
            sgl: quoteInterfaceData.sgl,
            dbl: quoteInterfaceData.dbl,
            tpl: quoteInterfaceData.tpl,
            cpl: quoteInterfaceData.cpl,
            adults: quoteInterfaceData.adults,
            childs: quoteInterfaceData.childs,
          },
        });
      }
    } catch (error) {
      alert("Error al realizar la busqueda");
      console.error("Error in request:", error);
      alert("Error en guardado de servicio de proveedor");
      // setShowLoader(false);
      setShowLoader(false);
    }
  };
  const [grupoActual, setGrupoActual] = useState(() => {
    const grupo = localStorage.getItem("groupUsuario");

    return grupo !== null ? parseInt(grupo) : currentUser.group_identifier;
  });
  useEffect(() => {
    const totalPax =
      quoteInterfaceData.sgl +
      quoteInterfaceData.dbl * 2 +
      quoteInterfaceData.tpl * 3 +
      quoteInterfaceData.cpl * 4;

    setQuoteInterfaceData({
      ...quoteInterfaceData,
      pax: totalPax,
    });
  }, [
    quoteInterfaceData.sgl,
    quoteInterfaceData.dbl,
    quoteInterfaceData.tpl,
    quoteInterfaceData.cpl,
  ]);

  return (
    <HeaderInfoStyles>
      <div className="container-first-row">
        <div className="container-alias">
          <div className="alias-box">
            <h3>Alias</h3>
            {isEditionMode ? (
              <input
                type="text"
                onChange={(e) => {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    alias: e.target.value,
                  });
                }}
                value={quoteInterfaceData.alias}
              />
            ) : (
              <h3>{quoteInterfaceData.alias}</h3>
            )}
          </div>
          {grupoActual !== 1018 && (
            <img
              src={editboton}
              alt=""
              width="25"
              onClick={() => {
                setIsEditionMode(!isEditionMode);
              }}
            />
          )}
        </div>
        <div className="base-pasajeros">
          <h3>Pasajeros en base a cotización: {baseInicialPax}</h3>
        </div>
      </div>

      <div className="container-info-header-one">
        <div>
          <h3>Nombre de cotización</h3>

          <h3 className="sub">{quoteInterfaceData.name}</h3>
        </div>
        <div>
          <h3>Fecha de llegada</h3>

          <h3>{quoteInterfaceData.arrival_date}</h3>
        </div>
        <div>
          <h3>Fecha de salida</h3>

          <h3>{quoteInterfaceData.departure_date}</h3>
        </div>
        <div>
          <h3>Agencia</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                setQuoteInterfaceData({
                  ...quoteInterfaceData,
                  agency: e.target.value,
                });
              }}
              value={quoteInterfaceData.agency}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.agency}</h3>
          )}
        </div>
      </div>
      <div className="container-info-header-two">
        <div>
          <h3>Temporada</h3>
          <h3 className="sub"></h3>
        </div>
        <div>
          <h3>Número pax</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                return;
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    pax: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      pax: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.pax}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.pax}</h3>
          )}
        </div>
        <div>
          <h3>SGL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    sgl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      sgl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.sgl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.sgl}</h3>
          )}
        </div>
        <div>
          <h3>DBL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    dbl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      dbl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.dbl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.dbl}</h3>
          )}
        </div>
        <div>
          <h3>TPL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    tpl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      tpl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.tpl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.tpl}</h3>
          )}
        </div>
        <div>
          <h3>CPL</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    cpl: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      cpl: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.cpl}
            />
          ) : (
            <h3 className="sub">{quoteInterfaceData.cpl}</h3>
          )}
        </div>
        <div>
          <h3>Adultos</h3>

          <h3 className="sub">{quoteInterfaceData.pax}</h3>
        </div>
        <div>
          <h3>Menores</h3>
          {isEditionMode ? (
            <input
              type="text"
              className="input-info"
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setQuoteInterfaceData({
                    ...quoteInterfaceData,
                    childs: 0,
                  });
                } else {
                  const parsedValue = parseFloat(value);
                  if (!isNaN(parsedValue)) {
                    setQuoteInterfaceData({
                      ...quoteInterfaceData,
                      childs: parsedValue,
                    });
                    // setDesgloceGeneralInfo({
                    //   ...desgloceGeneralInfo,
                    //   adults: parsedValue,
                    // });
                  }
                }
              }}
              value={quoteInterfaceData.childs}
            />
          ) : (
            <h3 className="sub"> {quoteInterfaceData.childs}</h3>
          )}
        </div>
      </div>

      <div className="container-info-header-three">
        <div className="opcional">
          {(bankListGuia?.length ?? 0) > 0 && (
            <h3 className="title-accounts">Cuenta(s) de banco:</h3>
          )}

          {bankListGuia?.map((cuentaBanco, index) => {
            return (
              <>
                <div className="container-bank-accounts">
                  <h3>{cuentaBanco.bank}</h3>
                  <h3>{cuentaBanco.bank_account}</h3>
                  <h3>
                    {cuentaBanco.currency_identifier == 1 ? "USD" : "MXN"}
                  </h3>
                </div>
              </>
            );
          })}
        </div>
      </div>

      {quoteInterfaceData.status_identifier != 3 && grupoActual !== 1018 && (
        <div className="buttons" style={{ backgroundColor: "transparent" }}>
          <img
            src={guardardatos}
            alt="leavePage"
            width="45px"
            onClick={saveGeneralDataOperacion}
            style={{ cursor: "pointer", marginBottom: "1rem" }}
          />
        </div>
      )}
    </HeaderInfoStyles>
  );
}

const HeaderInfoStyles = styled.div`
  .base-pasajeros h3 {
    font-size: 0.85vw;
  }
  border: rgb(8, 166, 165) solid 2px;
  padding: 0.8rem;
  .container-first-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .container-alias {
    display: flex;
    align-items: center;
  }
  .container-alias img {
    margin-left: 1rem;
    cursor: pointer;
  }

  .input-info {
    width: 60%;
    text-align: center;
  }
  .alias-box {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    align-items: center;
  }
  .alias-box h3 {
    margin-right: 1rem;
    font-size: 0.85vw;
  }
  .alias-box input {
    padding: 0.5rem;
    font-size: 0.85vw;
    outline: none;
    border: grey solid 1px;
  }
  .container-info-header-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  .container-info-header-one div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: center;
  }
  .container-info-header-one h3 {
    text-align: center;
    font-size: 0.85vw;
    margin-bottom: 1rem;
  }

  .container-info-header-two {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .container-info-header-two div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .container-info-header-two h3 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 0.85vw;
  }
  .sub {
    font-weight: 300;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 900px) {
    .container-info-header-two h3 {
      text-align: center;
      font-size: 1.1vw;
    }
    .container-info-header-one h3 {
      text-align: center;
      font-size: 1.3vw;
    }
    .alias-box h3 {
      font-size: 1.3vw;
    }
    .alias-box input {
      font-size: 1.3vw;
    }
    .container-info-header-one h3 {
      font-size: 1.3vw;
    }
    .container-info-header-two h3 {
      font-size: 1.3vw;
    }
  }
`;
