import { format } from "date-fns";

// Función de utilidad para formatear cantidades de dinero
export function formatearCantidad(cantidad: number): string {
    return cantidad.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  export function ocultarNumerosTarjeta(numero) {
    // Verificar si la entrada es una cadena que contiene solo números
    if (/^\d+$/.test(numero)) {
      // Tomar los últimos cuatro dígitos
      const ultimosCuatroDigitos = numero.slice(-4);
      
      return ultimosCuatroDigitos;
    } else {
      // Si la entrada no es válida, devolver un mensaje de error
      return 'Entrada no válida. Debe ser una cadena que contiene solo números.';
    }
  }

  export function getDateFormat(date: Date): string{

    return format(date, "dd/MM/yyyy");
  }