import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../../context/appContext";
import RowCotizacion from "../Components/RowCotizacion";
import {
  EntryDataResponse,
  EntryService,
  EntryServiceI,
  ProviderInterfaceOP,
  QuoteItemI,
  operationDataI,
  operationUpdateI,
  updateProviderI,
} from "../InterfacesOperacion/OperacionesI";
import { servicioDayElementI } from "../../../../context/InterfacesContext";
import IndividualService from "./IndividualService";
import deleteImage from "../../../../images/deletelogo.png";
import agregarImage from "../../../../images/AGREGAR.png";
import { Link, useNavigate } from "react-router-dom";
import { OperationContext } from "../../../../context/operationContext";
interface ElementAsignarGuiaI {
  servicioGuia: EntryServiceI;
  listServiciosGuia: EntryServiceI[];
  setListaServiciosGuia: React.Dispatch<React.SetStateAction<EntryServiceI[]>>
}
export default function ElementAsignarGuiaDos({
  servicioGuia,
  listServiciosGuia,
  setListaServiciosGuia
}: ElementAsignarGuiaI) {

  const [currentServicio, setCurrentServicio] =
    useState<EntryServiceI>(servicioGuia);

  useEffect(() => { 
    const newListServiciosGuia = listServiciosGuia.map((servicioActual, index)=>{
        return servicioActual.identifierService != servicioGuia.identifierService ? servicioActual: currentServicio
    });
    setListaServiciosGuia(newListServiciosGuia); 

  }, [currentServicio]);


  useEffect(()=>{
    console.log("La lista de servicios ha sido actualizada2::: ");
    console.log(listServiciosGuia);
  },[listServiciosGuia]);

  return (
    <ElementAsignarGuiaStyle>
      <div
        className="content-row"

        //    onClick={getCotizacion}
      >
        <div>
          <h3>{currentServicio.service_name}</h3>
        </div>
        <div>
          <h3>{currentServicio.arrival_date}</h3>
        </div>
        <div>
          <h3>{currentServicio.departure_date}</h3>
        </div>
        <div>
          <h3>{currentServicio.quantity1}</h3>
        </div>
        <div>
          <h3>{currentServicio.quantity2}</h3>
        </div>
        <div>
          <h3>{currentServicio.total_fee}</h3>
        </div>
        <div>
          <h3>{currentServicio.currency}</h3>
        </div>
        <div className="checkbox-container">
          <input
            checked={currentServicio.isSelected}
            type="checkbox"
            onChange={(e) =>{
                setCurrentServicio({
                    ...currentServicio, 
                    isSelected: e.target.checked
                })
            }}
          />
        </div>
      </div>
    </ElementAsignarGuiaStyle>
  );
}

const ElementAsignarGuiaStyle = styled.div`
  .checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: grey solid 1px;
    align-items: center;
    justify-content: center;
  }
  .content-row div {
    padding: 0.6rem;
  }
  .content-row div h3 {
    font-weight: 200;
    text-align: center;
    font-size: 0.85vw;
  }
  @media screen and (max-width: 900px) {
    .content-row div h3 {
      font-size: 1.2vw;
    }
  }
`;
