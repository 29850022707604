import React, { ChangeEvent, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ExpenseResponse, ProviderResponse } from "../../Operaciones/InterfacesOperacion/OperacionesI";
import HojaControlElement from "../../Operaciones/HojaControl/HojaControlElement";
import ElementGasto from "../../Operaciones/HojaControl/ElementGasto";
import { formatearCantidad } from "../../../components/functionsTs/utilidades";
import ElementGastoAux from "./ElementGastoAux";
interface PrestadorActual {
  prestadorExpense: ExpenseResponse | undefined;
  tipoPrestador: string;
}
export default function HojaControlGastoAux({
  prestadorExpense,
  tipoPrestador,
}: PrestadorActual) {
  
  const sumaTotalDlS = prestadorExpense?.providers.reduce((total, item)=>total + item.entry_total_usd,0);
  
  const sumaTotalMXN = prestadorExpense?.providers.reduce((total, item)=>total + item.entry_total_mxn,0);
  

  return (
    <HojaControlStyle>
      <div className="first-row">
        <div className="title-table">
          <h3>{prestadorExpense?.guide.guide_name}</h3>
        </div>
        <div className="botons-one"></div>
      </div>
      {prestadorExpense?.providers &&
        prestadorExpense.providers.map((expense, index) => {
          return (
            <>
              <ElementGastoAux expense={expense} />
            </>
          );
        })}
      <div className="element-container">    
        <div className="element-buttons"></div>
      </div>
    </HojaControlStyle>
  );
}

const HojaControlStyle = styled.div`
  .totales {
    font-weight: 600 !important;
  }
  .get-link {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
  }
  .element-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .element-container {
    display: flex;
  }
  .element-data {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border-right: black solid 1px;
    padding: 0.3rem;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    justify-content: center;
    align-items: center;
  }
  .element-buttons {
    width: 0%;
  }
  h3 {
    font-size: 0.8vw;
    text-align: center;
    font-weight: 300 !important;
  }
  ////////////////////////////
  .icon {
    cursor: pointer;
  }
  .first-row {
    display: flex;
    background-color: white !important;
  }
  .botons-one {
    width: 0%;
    display: flex;
    justify-content: center;
  }
  .box-buttons {
    margin-left: 1rem;
  }
  .box-buttons button {
    border: none;
    padding: 0.2rem;
    font-size: 0.85vw;
    cursor: pointer;
  }
  .guia-boton {
    margin-right: 1rem;
    background-color: #f6921e;
    color: white;
  }
  .desglose-boton {
    background-color: #29aae1;
    color: white;
  }
  .title-table {
    display: flex;
    align-items: center;
  }

  .list-elements {
    width: 100%;
  }
  .title-table {
    border: black solid 1px;
    color: black;
    background-color: orange;
    padding: 0.3rem !important;
    width: 100%;
    padding: 0.3rem;
  }
  .title-table {
    font-size: 0.75vw;
  }
`;
